import { createActions } from 'reduxsauce';
import { filter, startsWith, groupBy, sortBy, zip, without } from 'lodash';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  null: []
});

export const ExportTypes = Types;
export const ExportActions = Creators;
//

export function exportAsCSV() {
  return (dispatch, getState) => {
    const state = getState();
    const { exercise, login } = state;
    const { user } = login;
    if (!user) return '';

    const { exercises, searchTerm, exerciseSets } = exercise;
    const exerciseList = Object.keys(exercises).map(
      (exerciseId) => exercises[exerciseId],
    );
    const exercisesById = new Map(
      exerciseList.map((exercise) => [exercise.id, exercise]),
    );

    const exerciseSetsByDoneByUserId = groupBy(exerciseSets, 'doneByUserId');
    const sets = exerciseSetsByDoneByUserId[user.id] || [];

    // const userId = 'Nn0HpTU5XXVhFUzZWR3FHD54Va43';
    // const userData = users[userId];
    // const sets = userData.sets;
    const setsList = Object.keys(sets).map((setId) => sets[setId]);
    const setsProcessed = setsList.map((set) => {
      const exercise = exercisesById.get(set.exerciseId);
      return {
        ...set,
        date: formatDate(set.createdAt),
        exerciseName: (exercise && exercise.name) || set.exerciseId,
      };
    });

    const columns = ['date', 'exerciseName', 'repCount', 'weightLbs'];
    const setsDataRows = setsProcessed
      .map((set) => columns.map((col) => set[col]).join(','))
      .join('\n');

    const csvString = columns.join(',') + '\n' + setsDataRows;
    return csvString;
  };
}

function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
