import { createActions } from 'reduxsauce';
import LoginActions from './LoginRedux';
import FirebaseService from '../Services/FirebaseService';
import ExerciseActions from '../Redux/ExerciseStore';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  startup: null,
});

const firebase = FirebaseService.create();

export function loginSync(username, password) {
  return (dispatch) => {
    dispatch(LoginActions.loginRequest(username, password));

    return firebase.login(username, password).then((loginResponse) => {
      const userId = loginResponse.user.uid;
      firebase.getUserProfile(userId).then((userProfile) => {
        dispatch(LoginActions.loginSuccess(userProfile));
        dispatch(syncAll());
      });
    }).catch((e) => {
      dispatch(LoginActions.loginFailure());
    });
  };
}

export function syncAll() {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.user.id;

    firebase.getExercises().then((exercises) => {
      dispatch(ExerciseActions.forceUpdateExerciseStore({ exercises }));
    });
    firebase.getWorkouts().then((workouts) => {
      dispatch(ExerciseActions.forceUpdateExerciseStore({ workouts }));
    });
    firebase.getExerciseSets({ userId }).then((exerciseSets) => {
      dispatch(ExerciseActions.loadExerciseSets(exerciseSets));
    });
  };
}

export const StartupTypes = Types;
export default Creators;
