import React from 'react';
import omit from 'lodash/omit';
import { connect } from 'react-redux';
import ExerciseActions from '../../Redux/ExerciseStore';
import FirebaseService from '../../Services/FirebaseService';
import styles from './WorkoutUpdateScreen.module.scss';
import NavigationActions from '../../Redux/NavigationRedux';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import selectedExerciseSelector from '../../Selectors/selectedExerciseSelector';
import FormLabel from '../../components/Forms/FormLabel';
import FormInput from '../../components/Forms/FormInput';
import ScreenTitleBackButton from '../../components/ScreenTitleButtons/ScreenTitleBackButton';
import activeWorkoutSelector from '../../Selectors/activeWorkoutSelector';

const defaultWorkout = {
  name: '',
  exerciseIds: [],
};

class WorkoutUpdateScreen extends React.Component {
  constructor(props) {
    super(props);
    const { workout } = props;

    this.state = {
      showingExercisePicker: false,
      workout: workout || defaultWorkout,
    };
  }

  componentWillMount() {
    const title = this.state.workout.id ? 'Edit Workout' : 'Create New Workout';
    // NavigationActions.refresh({ title })
  }

  componentWillReceiveProps(newProps) {
    const userId = newProps.user.uid;
    this.setState({
      workout: {
        createdByUserId: userId,
        ...this.state.workout,
        updatedByUserId: userId,
      },
    });
  }

  _updateWorkoutProp(prop, value) {
    const { workout } = this.state;
    this.setState({
      workout: {
        ...workout,
        [prop]: value,
      },
    });
  }

  _saveWorkout() {
    const { workout } = this.state;
    const workoutPure = omit(workout, ['exercises']);
    const firebase = FirebaseService.create();

    if (workout.id) {
      firebase.updateWorkout(workoutPure).then((workout) => {
        this.props.updateWorkout(workout);
        this.props.popScreen();
      });
    } else {
      firebase.createWorkout(workoutPure).then((workout) => {
        this.props.createWorkout(workout);
        this.props.popScreen();
      });
    }
  }

  _renderBody() {
    const { workout } = this.state;
    const { id, name, exerciseIds } = workout;
    const exerciseCount = exerciseIds.length;
    const disabled = name.length === 0;

    return (
      <div className={styles.root}>
        <FormLabel>Workout name</FormLabel>
        <FormInput
          value={name}
          onChange={(ev) => this._updateWorkoutProp('name', ev.target.value)}
        />
        <FormLabel>Exercises</FormLabel>
        <FormInput
          value={`${exerciseCount} exercise${exerciseCount !== 1 ? 's' : ''}`}
          onClck={(ev) =>
            this.setState({ weightMultiplierStr: ev.target.value })
          }
        />
        <div className={styles.buttonRow}>
          <button onClick={() => this._saveWorkout()} disabled={disabled}>
            {id ? 'Save' : 'Create'}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { workout, user } = this.props;
    if (!workout || !user) return null;
    return (
      <ScreenLayoutComponent
        title={workout.name}
        body={this._renderBody()}
        leftNode={<ScreenTitleBackButton />}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workoutId } = props;
  console.log(`workoutId`, workoutId);

  const { activeWorkoutId, workouts, exercises } = state.exercise;
  const workout = workouts.find((workout) => workout.id === activeWorkoutId);

  return {
    workout,
    user: state.login.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popScreen: () => dispatch(NavigationActions.popScreen()),
    replaceScreen: (screen) =>
      dispatch(NavigationActions.replaceScreen(screen)),
    createWorkout: (workout) =>
      dispatch(ExerciseActions.createWorkout(workout)),
    updateWorkout: (workout) =>
      dispatch(ExerciseActions.updateWorkout(workout)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkoutUpdateScreen);
