import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import styles from './ExerciseLogListComponent.module.scss';
import oneRepMax from "one-rep-max";
import Sugar from "sugar-date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../Themes/Colors";

class ExerciseLogListComponent extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props)
  }

  _getOneRepMax(exerciseSet) {
    const {weightLbs, repCount} = exerciseSet
    if (repCount === 1) return weightLbs
    return Math.round(oneRepMax.epley({weight: weightLbs, reps: repCount}))
  }

  scrollToTop() {
    this._scrollView.scrollTop = 0
  }

  render() {
    return (
      <div className={styles.root} ref={(el) => this._scrollView = el} data-scrollable>
        {this.props.exerciseSets.map((set, i) => (
          <div key={i} className={styles.logsText}>
            <FontAwesomeIcon
              icon={faCircle}
              size="1x"
              className={styles.userIcon}
              styles={{ color: set.color || Colors.cloud }}
            />&nbsp;&nbsp;
            <div className={styles.repCount}>{set.repCount}</div>
            &nbsp;@&nbsp;
            <div className={styles.weight}>{set.weightLbs}</div>
            &nbsp;lbs. &nbsp;
            <div className={styles.ORMText}>
              {`${this._getOneRepMax(set)}`}
            </div>
            &nbsp;ORM&nbsp;
            <div className={styles.logsTextAgo}>
              ({`${new Sugar.Date(set.createdAt).relative()}`})
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ExerciseLogListComponent
