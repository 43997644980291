import styles from "./formStyles.module.scss";
import React from "react";

const FormInput = (props) => {
  const onChange = (e) => {
    props.onChange(e.target.value);
  };
  return (
    <input className={styles.input} type="text" {...{...props, onChange}}></input>
  )
};

export default FormInput;
