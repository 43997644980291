import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

console.log(`Booting up LiftLog v0.1.0`);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (installingWorker) => {
    // The page just loaded, so immediately switch to the new version of the site.
    // If we want to show a banner like "Click to reload now" have it call this.
    installingWorker.skipWaiting();
  },
});
