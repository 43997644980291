import React, { Component } from 'react';
import styles from './HomeScreenComponent.module.scss';
import BodyDiagramComponent from '../../components/BodyDiagramComponent/BodyDiagramComponent';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { loginSync } from '../../Redux/SyncingRedux';
import FormLabel from '../../components/Forms/FormLabel';
import FormInput from '../../components/Forms/FormInput';
import classNames from 'classnames';

class HomeScreenComponent extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    fetching: PropTypes.bool,
    loginSync: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      stage: 'entry',
      username: '',
      password: '',
    };
  }

  componentWillReceiveProps(newProps) {
    this.forceUpdate();
    if (newProps.user && !newProps.fetching) {
      // NavigationActions.pop()
    }
  }

  handlePressLogin = () => {
    const { username, password } = this.state;
    // attempt a login - a saga is listening to pick it up from here.
    this.props.loginSync(username, password);
  };

  handleChangeUsername = (text) => {
    this.setState({ username: text });
  };

  handleChangePassword = (text) => {
    this.setState({ password: text });
  };

  _renderLoginBox() {
    const { username, password } = this.state;
    const { fetching } = this.props;
    return (
      <div className={styles.login}>
        <div className={styles.inputsRows}>
          <FormLabel>email</FormLabel>
          <FormInput
            type="text"
            onChange={this.handleChangeUsername}
            value={username}
            disabled={fetching}
            placeholder={'user@example.com'}
          />
          <FormLabel>password</FormLabel>
          <FormInput
            type="password"
            onChange={this.handleChangePassword}
            value={password}
            disabled={fetching}
            placeholder={'******'}
          />
        </div>
        <div
          className={classNames({
            [styles.loginRow]: true,
            [styles.disabled]: fetching,
          })}
        >
          <button
            className={styles.cancel}
            onClick={() => this.setState({ stage: 'button' })}
          >
            Cancel
          </button>
          <button
            className={styles.loginButton}
            onClick={this.handlePressLogin}
          >
            Sign In
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const { stage } = this.state;
    return (
      <div className={styles.root}>
        <div className={styles.title}>
          <span className={styles.lift}>Lift</span>
          <span className={styles.log}>log</span>
        </div>
        <div className={styles.body}>
          <div className={styles.diagram}>
            <BodyDiagramComponent />
          </div>
          {!user && (
            <div className={styles.menu}>
              {stage === 'button' && (
                <button
                  className={styles.button}
                  onClick={() => this.setState({ stage: 'entry' })}
                >
                  Login
                </button>
              )}
              {stage === 'entry' && this._renderLoginBox()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: state.login.fetching,
    user: state.login.user,
  };
};

export default connect(
  mapStateToProps,
  { loginSync },
)(HomeScreenComponent);
