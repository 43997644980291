import React from 'react';
import chroma from 'chroma-js';
import { sumBy } from 'lodash';
import parseSVG from 'svg-path-parser';

const paths = [
  'M112.3468,0.2964 C117.2348,-0.7876 121.1088,1.2454 124.8838,4.0724 C128.5768,6.8374 128.6448,6.6964 129.0568,11.2254 C129.3198,14.1144 129.6148,17.0104 130.1338,19.8594 C130.2868,20.6984 131.1378,21.5444 131.8798,22.1094 C132.9888,22.9544 133.3438,23.8504 133.0248,25.2034 C132.6138,26.9414 132.5108,28.7614 131.9938,30.4604 C131.7538,31.2484 130.9638,32.0254 130.2208,32.4714 C128.5538,33.4714 127.7718,34.7254 127.9628,36.7284 C128.0868,38.0234 127.5898,39.3844 127.7528,40.6674 C128.1478,43.7674 126.7938,45.8054 124.4278,47.6084 C122.0828,49.3964 120.0078,51.5344 117.7388,53.4294 C117.1918,53.8874 116.3678,54.0714 115.6388,54.2384 C115.0868,54.3644 114.4828,54.2634 113.9018,54.2634 C107.7098,54.2634 107.7058,54.2684 102.9808,50.2424 C101.6948,49.1474 100.5348,47.8764 99.1518,46.9274 C97.2018,45.5884 96.5498,43.8084 96.5538,41.5524 C96.5568,39.4974 96.4338,37.4324 96.1928,35.3924 C96.1078,34.6664 95.6918,33.6924 95.1208,33.3624 C92.8968,32.0764 91.8208,30.2524 91.7988,27.7214 C91.7948,27.2564 91.6698,26.7754 91.5148,26.3314 C90.7688,24.1964 91.3878,22.6414 93.2688,21.3684 C93.8508,20.9734 94.2358,19.9954 94.3378,19.2344 C94.8148,15.6794 95.1968,12.1104 95.5368,8.5384 C95.6588,7.2474 96.0358,6.2904 97.2408,5.5814 C99.6908,4.1404 102.0188,2.4914 104.4678,1.0494 C105.2338,0.5984 106.2188,0.3864 107.1208,0.3284 C108.8568,0.2184 110.6038,0.2964 112.3468,0.2964',
  'M70.0502,102.3373 C72.2192,100.7103 74.3592,99.1883 76.3942,97.5363 C77.1782,96.8993 77.9092,96.0583 78.3452,95.1553 C80.8912,89.8833 83.3642,84.5743 85.8072,79.2523 C86.2572,78.2703 86.8712,77.9893 87.9162,77.7393 C91.4662,76.8873 94.5382,78.3213 97.7132,79.3933 C100.7592,80.4223 103.7992,81.4743 106.8742,82.4113 C107.9722,82.7453 108.3592,83.3073 108.3972,84.4173 C108.6242,91.0643 108.9002,97.7093 109.1712,104.3553 C109.2532,106.3593 109.3542,108.3623 109.4792,110.3643 C109.5352,111.2723 109.2172,111.6093 108.2602,111.7563 C102.5372,112.6363 96.8302,113.6183 91.1122,114.5283 C90.6322,114.6053 90.0212,114.5433 89.6152,114.3033 C83.2632,110.5313 76.9332,106.7213 70.6032,102.9123 C70.4362,102.8123 70.3222,102.6253 70.0502,102.3373',
  'M154.8315,102.5168 C150.6225,104.9568 146.7435,107.1888 142.8825,109.4518 C140.2865,110.9728 137.7325,112.5668 135.1215,114.0598 C134.5715,114.3748 133.8175,114.6068 133.2155,114.5128 C127.5975,113.6288 121.9975,112.6348 116.3795,111.7548 C115.4195,111.6048 115.1545,111.2868 115.1965,110.3578 C115.5865,101.7588 115.9645,93.1578 116.2765,84.5558 C116.3225,83.2998 116.7515,82.7248 117.9515,82.3358 C122.2655,80.9388 126.4855,79.2168 130.8545,78.0368 C132.8955,77.4858 135.1975,77.8338 137.3755,77.9268 C137.7465,77.9418 138.1905,78.6748 138.4225,79.1608 C140.5565,83.6388 142.6905,88.1178 144.7415,92.6338 C145.8735,95.1288 147.3945,97.2158 149.7345,98.7278 C151.4065,99.8088 152.9425,101.1008 154.8315,102.5168',
  'M142.9866,215.0738 C143.0916,216.8938 143.2296,218.7128 143.2966,220.5338 C143.5746,228.0248 143.9966,235.5168 144.0146,243.0088 C144.0216,246.0148 143.2546,249.0498 142.6166,252.0238 C139.1046,268.3968 135.5146,284.7528 131.9816,301.1208 C131.6876,302.4818 131.2926,303.4548 129.4536,302.7498 C129.2026,300.0878 128.9256,297.3268 128.6856,294.5628 C128.0376,287.1048 127.4006,279.6468 126.7696,272.1878 C126.3076,266.7248 125.8126,261.2638 125.4476,255.7948 C125.3746,254.6948 125.6156,253.4698 126.0606,252.4558 C131.3546,240.3788 136.7136,228.3308 142.0586,216.2768 C142.2456,215.8568 142.4716,215.4548 142.6796,215.0448 C142.7826,215.0538 142.8846,215.0638 142.9866,215.0738',
  'M81.4194,214.5805 C81.8054,215.3465 82.0534,215.7885 82.2574,216.2505 C87.6024,228.3105 92.9584,240.3645 98.2524,252.4465 C98.6744,253.4085 98.8924,254.5775 98.8144,255.6215 C98.0904,265.3515 97.2994,275.0775 96.4894,284.8005 C96.0204,290.4255 95.4754,296.0435 94.9794,301.6665 C94.9144,302.3995 94.8384,303.1875 93.8674,302.9125 C93.3514,302.7675 92.6614,302.2425 92.5584,301.7765 C88.8654,285.0575 85.4564,268.2715 81.4784,251.6205 C79.8864,244.9565 80.4974,238.4085 80.6174,231.7915 C80.7144,226.4585 81.0334,221.1295 81.2594,215.7985 C81.2724,215.5095 81.3334,215.2215 81.4194,214.5805',
  'M120.5501,412.9462 C122.0871,407.2092 123.4951,401.9722 124.8921,396.7322 C125.9371,392.8152 127.0241,388.9072 127.9781,384.9672 C128.3351,383.4952 128.4851,381.9482 128.5291,380.4292 C128.8381,369.6042 129.0901,358.7772 129.3801,347.9512 C129.3981,347.2792 129.4531,346.5582 129.7061,345.9502 C132.4281,339.4222 135.1921,332.9122 137.9481,326.3992 C138.0471,326.1682 138.1861,325.9532 138.5051,325.3612 C138.9301,328.0302 139.3091,330.3212 139.6571,332.6162 C140.8361,340.3812 142.0201,348.1462 143.1541,355.9182 C143.2791,356.7752 143.2551,357.7232 143.0291,358.5542 C138.6291,374.7282 134.1771,390.8872 129.7811,407.0612 C129.4581,408.2472 128.8801,408.9562 127.7701,409.4542 C125.4711,410.4852 123.2291,411.6402 120.5501,412.9462',
  'M103.7797,412.9629 C101.0137,411.6589 98.5107,410.5389 96.0787,409.2829 C95.5677,409.0189 95.1357,408.3049 94.9707,407.7139 C90.3567,391.2309 85.7777,374.7379 81.2117,358.2409 C81.0627,357.7019 80.9887,357.0929 81.0697,356.5449 C82.5487,346.5449 84.0567,336.5479 85.5647,326.5519 C85.5947,326.3519 85.7027,326.1649 85.8717,325.7089 C86.1717,326.1959 86.4067,326.4889 86.5487,326.8219 C89.2287,333.1309 91.9047,339.4429 94.5577,345.7639 C94.7567,346.2359 94.8657,346.7779 94.8807,347.2919 C95.2217,358.9519 95.4197,370.6179 95.9547,382.2689 C96.0947,385.3099 97.2087,388.3209 97.9927,391.3159 C99.8577,398.4429 101.7937,405.5529 103.7797,412.9629',
  'M25.7713,225.3606 C23.0783,224.8766 23.0723,224.8746 22.2763,227.0176 C21.4133,229.3366 20.7263,231.7386 19.6663,233.9626 C18.3043,236.8186 18.4483,236.5826 15.5143,235.6866 C16.8083,232.0426 18.0993,228.4056 19.4233,224.6746 C19.0203,224.5646 18.5233,224.4326 18.0283,224.2916 C16.9523,223.9836 16.3203,224.2896 15.8663,225.4116 C14.6993,228.2916 13.3943,231.1166 12.1363,233.9606 C11.1363,236.2226 9.9983,236.6026 7.4613,235.4286 C9.0513,231.7656 10.6573,228.1036 12.2173,224.4216 C12.3963,223.9986 12.5273,223.3336 12.3213,223.0116 C11.7043,222.0476 10.7383,222.1226 10.1073,223.1126 C8.4613,225.6926 6.8403,228.2886 5.2133,230.8806 C3.9753,232.8546 3.3793,233.0416 0.9603,232.0996 C1.2153,231.5886 1.4553,231.0826 1.7153,230.5876 C4.5653,225.1716 7.3993,219.7486 10.2883,214.3546 C10.7453,213.5006 10.9443,212.6796 10.1423,212.1206 C9.7433,211.8426 8.9043,211.9406 8.3733,212.1486 C5.7323,213.1826 3.1263,214.3096 0.5323,215.3936 C-0.3457,214.1226 -0.0807,213.3336 0.9143,212.3056 C5.1063,207.9726 10.0333,204.9336 15.7293,202.9766 C19.0793,201.8256 19.2103,201.5966 20.3263,204.9956 C21.1053,207.3666 22.4133,208.3726 24.8233,208.4246 C26.1773,208.4546 27.5243,208.8476 29.0933,209.1136 C29.0423,213.8436 30.3363,218.5506 28.9163,223.3346 C27.8253,227.0116 27.0373,230.7796 26.1403,234.5136 C25.9223,235.4176 25.6683,236.1196 24.4883,235.8836 C23.6513,235.7166 23.3203,235.4366 23.5623,234.5006 C24.3383,231.4946 25.0273,228.4676 25.7713,225.3606',
  'M216.5433,235.7859 C215.2773,235.8699 214.2833,236.0539 213.3223,235.9449 C212.9073,235.8979 212.4143,235.3149 212.2053,234.8609 C210.8393,231.8909 209.5443,228.8879 208.2203,225.8979 C207.3013,223.8219 206.9883,223.7199 204.6013,224.8269 C205.8473,228.4179 207.1003,232.0299 208.3513,235.6329 C205.7223,236.6969 205.5203,236.6139 204.6093,234.1629 C203.7283,231.7939 202.8523,229.4219 201.9713,227.0519 C201.1453,224.8289 201.0183,224.7669 198.4893,225.4209 C199.1743,228.3339 199.8523,231.2539 200.5533,234.1679 C200.7293,234.8969 201.1043,235.5979 199.9173,235.8539 C198.8663,236.0789 198.3623,235.7249 198.1173,234.7029 C196.9883,229.9939 195.7713,225.3049 194.7123,220.5809 C194.4673,219.4879 194.6983,218.2819 194.7483,217.1289 C194.8633,214.4979 195.0013,211.8669 195.1363,209.1199 C197.3393,208.7519 199.4683,208.4559 201.5633,208.0029 C202.1153,207.8839 202.7663,207.3789 203.0173,206.8749 C203.8103,205.2799 204.4343,203.6019 205.2303,201.7029 C209.8443,204.2089 215.1933,204.8819 218.9873,208.7709 C220.2013,210.0149 221.5283,211.1639 222.8913,212.2449 C223.9773,213.1069 224.3783,213.9829 223.3633,215.3749 C221.0853,214.4269 218.7483,213.3799 216.3513,212.4969 C215.5373,212.1969 214.7223,211.0409 213.6903,212.1359 C212.6183,213.2749 213.5823,214.1869 214.0693,215.1179 C216.8323,220.4029 219.6183,225.6759 222.3923,230.9549 C222.5843,231.3209 222.7423,231.7059 222.9163,232.0819 C220.7063,233.0689 219.9283,232.8479 218.7323,230.9439 C217.1043,228.3509 215.4703,225.7599 213.8463,223.1639 C213.4683,222.5609 213.1833,221.9829 212.2573,222.5049 C211.3963,222.9909 211.1933,223.4389 211.6243,224.3829 C213.0733,227.5509 214.4303,230.7609 215.8163,233.9579 C216.0233,234.4339 216.2013,234.9229 216.5433,235.7859',
  'M192.9417,205.4157 C192.6067,205.1257 192.4247,205.0137 192.3017,204.8557 C184.9797,195.4467 177.6417,186.0507 170.3797,176.5957 C169.6347,175.6267 169.3087,174.3217 168.8527,173.1477 C166.3697,166.7557 163.9067,160.3567 161.4387,153.9587 C161.3657,153.7697 161.3107,153.5747 161.1467,153.0757 C161.7537,153.3817 162.1527,153.5427 162.5097,153.7677 C166.3517,156.1907 170.1997,158.6027 174.0097,161.0747 C174.5967,161.4547 175.1187,162.0507 175.4707,162.6617 C179.3787,169.4297 183.2197,176.2357 187.1347,182.9987 C189.7727,187.5537 192.4717,192.0737 195.2087,196.5707 C195.8277,197.5857 195.9027,198.4567 195.4187,199.5227 C194.5567,201.4247 193.7937,203.3717 192.9417,205.4157',
  'M63.4648,153.147 C62.9628,154.517 62.5878,155.638 62.1458,156.731 C59.9798,162.091 57.6268,167.383 55.6808,172.821 C54.4658,176.217 52.2378,178.83 50.1428,181.584 C44.3908,189.147 38.6028,196.683 32.8238,204.226 C32.5468,204.587 32.2258,204.914 31.7188,205.494 C30.6878,202.992 29.7198,200.736 28.8508,198.442 C28.7168,198.089 28.9368,197.512 29.1538,197.13 C35.7848,185.48 42.4338,173.841 49.0938,162.208 C49.2948,161.856 49.5888,161.503 49.9268,161.293 C54.1348,158.676 58.3578,156.084 62.5798,153.491 C62.7458,153.39 62.9458,153.346 63.4648,153.147',
  'M52.6401,144.9426 C52.1591,142.2436 51.8871,139.8346 51.2751,137.5166 C50.5151,134.6326 50.9881,131.9366 51.7421,129.1506 C53.4471,122.8436 55.0341,116.5046 56.5811,110.1566 C56.8561,109.0266 57.3851,108.4306 58.4311,108.0716 C60.6721,107.3036 62.8781,106.4316 65.1271,105.6916 C65.5821,105.5426 66.1681,105.7906 66.6931,105.8616 C66.7391,105.8676 66.7801,105.9186 66.8231,105.9486 C71.4931,109.1166 71.4541,109.1096 70.4331,114.6656 C69.8711,117.7186 69.4771,120.8026 68.9841,123.8686 C68.9021,124.3796 68.7721,124.9066 68.5441,125.3666 C66.4821,129.5286 64.3971,133.6776 62.3091,137.8266 C62.1471,138.1476 61.9801,138.5276 61.7041,138.7196 C58.8061,140.7376 55.8851,142.7216 52.6401,144.9426',
  'M171.7324,144.9872 C168.6584,142.8762 165.9084,141.0232 163.2094,139.0992 C162.7734,138.7882 162.5104,138.1882 162.2534,137.6792 C160.2544,133.7252 158.1634,129.8122 156.3484,125.7752 C155.6134,124.1422 155.4164,122.2442 155.1124,120.4432 C154.5534,117.1232 154.1394,113.7792 153.5724,110.4612 C153.4004,109.4522 153.8664,108.9942 154.4944,108.3432 C157.1734,105.5612 159.8724,105.4162 163.4494,106.9832 C166.3074,108.2352 167.7914,109.6572 168.4494,112.7412 C169.8664,119.3842 171.7344,125.9302 173.4084,132.5182 C173.5104,132.9232 173.6384,133.3652 173.5754,133.7612 C173.0034,137.3892 172.3914,141.0102 171.7324,144.9872',
  'M93.8804,167.6384 C98.0774,168.1634 102.0864,168.6294 106.0774,169.2174 C106.5024,169.2804 106.9894,169.9984 107.1654,170.5094 C108.7254,175.0314 109.4534,179.6434 109.1714,184.4764 C108.7544,191.6364 108.6434,198.8144 108.3944,205.9844 C108.3714,206.6434 108.2904,207.3004 108.2084,208.3024 C105.1784,206.3224 102.4164,204.5354 99.6844,202.7034 C99.4224,202.5274 99.2494,202.0924 99.1964,201.7524 C97.4264,190.4734 95.6754,179.1914 93.8804,167.6384',
  'M130.4689,167.7406 C129.8439,171.6866 129.2539,175.4276 128.6599,179.1676 C127.4709,186.6496 126.2849,194.1316 125.0809,201.6106 C125.0189,201.9966 124.9129,202.5156 124.6409,202.7076 C122.0249,204.5506 119.3689,206.3356 116.4199,208.3456 C116.3079,206.0776 116.1859,204.1476 116.1219,202.2156 C115.8739,194.7826 115.3929,187.3456 115.5209,179.9176 C115.5779,176.5756 116.8389,173.2486 117.6289,169.9326 C117.7059,169.6066 118.2879,169.2326 118.6779,169.1776 C122.3229,168.6616 125.9759,168.2096 129.6279,167.7436 C129.8229,167.7186 130.0229,167.7406 130.4689,167.7406',
  'M107.6527,413.5635 C108.5207,415.9425 109.6637,418.0585 109.4917,420.6185 C109.2367,424.4105 109.2517,428.2225 108.9447,432.0095 C108.7457,434.4635 108.1507,436.8835 107.7887,439.3275 C107.6517,440.2555 107.2067,440.6165 106.3047,440.5115 C106.0447,440.4815 105.7697,440.4655 105.5147,440.5125 C103.6727,440.8445 102.6097,440.1785 102.4317,438.2335 C102.3897,437.7755 102.2177,437.2765 101.9497,436.9105 C101.7767,436.6735 101.2897,436.5005 101.0027,436.5645 C100.7797,436.6145 100.5107,437.0715 100.5037,437.3535 C100.4797,438.2355 100.5697,439.1195 100.6317,440.2945 C98.9987,439.8055 97.5637,439.3755 96.1287,438.9465 C93.9187,438.2865 91.7267,437.5525 89.4897,437.0025 C88.5247,436.7645 88.2927,436.3585 88.1917,435.4235 C87.9427,433.1155 88.4107,431.1095 89.7757,429.1835 C92.2707,425.6605 94.5987,422.0195 97.0227,418.4455 C97.3097,418.0235 97.6967,417.5605 98.1437,417.3745 C101.2507,416.0845 104.3857,414.8635 107.6527,413.5635',
  'M116.7745,413.4717 C120.1865,414.8857 123.5225,415.8057 126.3105,417.5827 C128.2455,418.8167 129.5665,421.1377 130.9095,423.1417 C132.6085,425.6787 133.9995,428.4197 135.6235,431.0097 C136.7415,432.7937 135.8355,434.6107 135.9225,436.4067 C135.9295,436.5587 135.5895,436.8107 135.3695,436.8757 C131.3555,438.0617 127.3345,439.2257 123.0835,440.4627 C123.3185,439.2057 123.5425,438.0107 123.7765,436.7627 C122.4415,436.1437 122.2395,437.0747 121.9795,437.9417 C121.7365,438.7467 121.4725,439.5457 121.1665,440.5087 L119.1455,440.5087 L117.0215,440.5087 C116.5835,438.1107 115.9485,435.8087 115.7815,433.4717 C115.4495,428.8437 115.2065,424.1937 115.2755,419.5587 C115.3055,417.5887 116.2135,415.6337 116.7745,413.4717',
  'M133.2549,193.1521 C132.3089,191.4581 131.7409,189.9551 132.6909,188.1541 C134.9949,183.7871 135.4479,179.0691 135.3639,174.1991 C135.2859,169.7161 135.5329,165.2281 135.6889,160.7431 C135.7049,160.2861 135.9569,159.6651 136.3089,159.4191 C139.1949,157.4001 142.1309,155.4511 145.2009,153.3801 C145.3739,153.8891 145.5519,154.2021 145.5789,154.5281 C145.9319,158.7671 146.3149,163.0041 146.5849,167.2491 C146.8759,171.8291 147.0339,176.4171 147.3029,180.9981 C147.3709,182.1551 146.8519,182.7091 145.9449,183.3731 C142.3729,185.9901 138.9119,188.7581 135.4109,191.4711 C134.7109,192.0131 134.0149,192.5591 133.2549,193.1521',
  'M78.9427,153.3565 C81.4187,155.0165 83.5477,156.6595 85.8757,157.9415 C88.0477,159.1385 88.7117,160.6965 88.6677,163.1355 C88.5767,168.0925 88.9917,173.0565 88.9457,178.0155 C88.9117,181.6295 90.0937,184.8545 91.5277,188.0165 C92.3477,189.8235 92.0697,191.3405 91.0227,193.0405 C89.2117,191.6255 87.4347,190.2425 85.6637,188.8525 C83.2137,186.9295 80.7937,184.9675 78.3047,183.0955 C77.5217,182.5065 77.3707,181.9145 77.4277,180.9675 C77.8457,174.0165 78.1787,167.0595 78.5457,160.1055 C78.6567,157.9995 78.7927,155.8945 78.9427,153.3565',
  'M101.6696,259.4934 C103.6966,262.1234 104.5176,265.0704 105.2356,268.0884 C106.6256,273.9364 108.1516,279.7524 109.6026,285.5864 C109.7266,286.0854 109.8646,286.6614 109.7386,287.1314 C107.9806,293.6424 106.1786,300.1404 104.2916,306.9894 C103.1346,306.2854 102.1396,305.5474 101.0386,305.0364 C98.2366,303.7364 97.2136,301.9774 97.7056,298.6194 C98.8056,291.1174 99.2956,283.5254 100.0336,275.9704 C100.5156,271.0334 100.9966,266.0964 101.4806,261.1594 C101.5346,260.6034 101.6066,260.0484 101.6696,259.4934',
  'M178.7221,142.9007 C181.0551,145.7257 184.1701,147.3577 185.2121,150.9827 C186.1211,154.1437 187.4981,157.1807 188.8061,160.2137 C190.8291,164.9037 190.9881,169.9447 191.7801,174.8707 C191.9611,175.9947 192.2611,177.1587 192.7861,178.1567 C196.3471,184.9307 199.9631,191.6767 203.6131,198.4037 C204.2751,199.6227 204.0761,200.2447 202.5741,200.5327 C201.7351,199.1897 200.8641,197.8177 200.0161,196.4317 C196.4591,190.6247 192.9401,184.7937 189.3471,179.0077 C185.8181,173.3247 182.2941,167.6357 178.5971,162.0617 C177.4961,160.4017 177.3761,158.7627 177.5061,156.9677 C177.8151,152.7047 178.1451,148.4427 178.4741,144.1817 C178.4981,143.8827 178.5861,143.5897 178.7221,142.9007',
  'M45.7613,143.1867 C45.9823,145.8657 46.2113,148.3637 46.3893,150.8657 C46.5843,153.6017 46.7593,156.3407 46.8773,159.0807 C46.9003,159.6167 46.7483,160.2407 46.4703,160.6977 C38.4403,173.8557 30.3833,186.9967 22.3283,200.1387 C22.2483,200.2697 22.1233,200.3747 21.9163,200.6077 C21.4573,200.3597 20.9783,200.1017 20.3443,199.7587 C21.6613,197.2847 22.9143,194.9197 24.1763,192.5607 C26.8143,187.6277 29.6063,182.7677 32.0223,177.7287 C32.9213,175.8527 33.1903,173.5727 33.3213,171.4517 C33.6453,166.2077 35.3463,161.3617 37.1663,156.5267 C37.6123,155.3407 38.1943,154.1767 38.4323,152.9477 C39.2323,148.8137 42.0883,146.1827 45.0253,143.5867 C45.1673,143.4617 45.3643,143.3997 45.7613,143.1867',
  'M122.6363,259.3848 C122.8973,261.7748 123.1763,264.1628 123.4163,266.5558 C124.1723,274.1058 124.9253,281.6558 125.6573,289.2078 C126.0643,293.4048 126.4373,297.6038 126.7893,301.8058 C126.8193,302.1658 126.6923,302.7218 126.4383,302.8968 C124.4953,304.2338 122.5023,305.4988 120.3093,306.9268 C119.4573,303.8048 118.6753,300.9368 117.8903,298.0698 C116.9703,294.7128 116.0043,291.3678 115.1593,287.9918 C114.9413,287.1208 114.9093,286.1068 115.1203,285.2388 C116.9283,277.8168 118.7843,270.4068 120.6983,263.0118 C121.0313,261.7268 121.7043,260.5308 122.2203,259.2928 C122.3583,259.3238 122.4973,259.3538 122.6363,259.3848',
  'M104.0285,404.7247 C101.6955,393.3187 99.2925,382.0497 99.8025,370.2667 C100.3025,358.7177 101.2945,347.3617 104.3205,336.2377 C104.5035,336.2397 104.6875,336.2417 104.8715,336.2437 C105.0505,336.7647 105.2555,337.2787 105.4035,337.8077 C105.8845,339.5327 106.1535,341.3457 106.8475,342.9787 C109.6625,349.6057 108.5545,356.4727 108.2785,363.2947 C108.2635,363.6567 108.1575,364.0277 108.0295,364.3697 C106.5815,368.2567 105.1205,372.1387 103.6675,376.0237 C103.6135,376.1697 103.5815,376.3347 103.5855,376.4887 C103.6925,380.2297 103.8035,383.9707 103.9225,387.7107 C103.9365,388.1307 104.0355,388.5487 104.0395,388.9677 C104.0805,393.8277 104.1135,398.6877 104.1385,403.5477 C104.1405,403.9397 104.0665,404.3327 104.0285,404.7247',
  'M135.2821,301.1172 C136.4501,295.8232 137.5901,290.6302 138.7421,285.4402 C141.0361,275.1062 143.3231,264.7712 145.6441,254.4442 C146.0541,252.6202 146.7691,250.8542 147.0621,249.0172 C147.3731,247.0642 147.5031,245.0522 147.4431,243.0742 C147.2371,236.2722 146.9111,229.4742 146.6371,222.6732 C146.6261,222.4172 146.6711,222.1582 146.7081,221.6532 C147.8181,221.6532 148.9191,221.5402 149.9721,221.7152 C150.3361,221.7762 150.8241,222.5412 150.8441,223.0022 C151.0431,227.5902 151.1631,232.1802 151.2921,236.7702 C151.4171,241.2022 151.5631,245.6332 151.6151,250.0662 C151.6281,251.2042 151.4431,252.3782 151.1421,253.4792 C149.6661,258.8642 148.1551,264.2412 146.5911,269.6022 C143.6331,279.7422 140.6161,289.8642 137.6651,300.0062 C137.2971,301.2702 136.6231,301.5612 135.2821,301.1172',
  'M88.9388,301.1435 C87.6808,301.5245 87.0378,301.2985 86.6668,299.9785 C82.2028,284.0975 77.6798,268.2325 73.1798,252.3605 C73.0528,251.9105 72.9428,251.4305 72.9568,250.9685 C73.2278,241.6755 73.5308,232.3845 73.7878,223.0915 C73.8168,222.0435 74.0908,221.5175 75.2378,221.6225 C76.1118,221.7025 76.9988,221.6385 77.8848,221.6385 C77.8848,223.5725 77.9448,225.3035 77.8748,227.0285 C77.6208,233.2535 77.1838,239.4755 77.1128,245.7015 C77.0868,248.0225 77.7978,250.3695 78.3068,252.6755 C81.7728,268.3755 85.2758,284.0675 88.7588,299.7635 C88.8578,300.2085 88.8788,300.6705 88.9388,301.1435',
  'M120.2193,336.1644 C125.4403,358.4794 126.4223,380.8454 120.5563,403.2944 C120.5563,402.7874 120.5543,402.2804 120.5573,401.7734 C120.6053,393.6444 120.6703,385.5154 120.6813,377.3864 C120.6823,376.4624 120.4853,375.5044 120.2003,374.6204 C119.4713,372.3684 118.7553,370.0994 117.8233,367.9274 C116.4143,364.6424 116.4073,361.1824 116.0413,357.7224 C115.4663,352.2894 116.3173,347.1444 118.0623,342.0494 C118.7233,340.1174 119.1703,338.1114 119.7153,336.1394 C119.8833,336.1474 120.0513,336.1564 120.2193,336.1644',
  'M104.605,260.5591 C102.551,255.9701 100.487,251.3861 98.445,246.7921 C93.282,235.1791 88.112,223.5701 83.003,211.9341 C82.556,210.9171 82.376,209.7171 82.347,208.5941 C82.263,205.2981 82.317,201.9971 82.317,198.6991 C82.495,198.6801 82.673,198.6611 82.851,198.6421 C83.011,198.9451 83.189,199.2391 83.328,199.5501 C89.368,213.0261 95.467,226.4771 101.374,240.0111 C102.297,242.1261 102.373,244.6181 102.791,246.9441 C103.566,251.2721 104.306,255.6061 105.055,259.9381 C105.08,260.0801 105.04,260.2321 105.031,260.3801 C104.889,260.4401 104.747,260.4991 104.605,260.5591',
  'M119.6495,259.7172 C120.1615,256.2892 120.6735,252.8612 121.1865,249.4332 C121.4045,247.9752 121.7135,246.5242 121.8295,245.0582 C122.2675,239.5202 125.2555,234.9212 127.3805,230.0252 C131.7465,219.9672 136.3055,209.9922 140.7895,199.9852 C140.9785,199.5622 141.2075,199.1582 141.5445,198.4972 C141.6955,199.0132 141.8345,199.2742 141.8385,199.5372 C141.8935,202.9672 141.9485,206.3972 141.9495,209.8272 C141.9495,210.3832 141.7705,210.9732 141.5425,211.4892 C134.5325,227.2942 127.5065,243.0922 120.4795,258.8902 C120.3365,259.2102 120.1495,259.5102 119.9835,259.8202 C119.8725,259.7852 119.7605,259.7512 119.6495,259.7172',
  'M141.9086,78.4807 C144.3156,78.4807 146.5126,78.4437 148.7056,78.5147 C149.1056,78.5277 149.5316,78.9347 149.8776,79.2377 C153.2946,82.2287 156.7056,85.2257 160.0936,88.2487 C160.5516,88.6577 161.0046,89.1647 161.2336,89.7207 C163.0996,94.2567 164.9136,98.8137 166.7336,103.3687 C166.8416,103.6387 166.8646,103.9427 166.9866,104.5017 C166.2966,104.1857 165.8326,103.9957 165.3886,103.7687 C161.0656,101.5617 156.7286,99.3817 152.4426,97.1077 C151.7106,96.7197 151.0356,96.0157 150.6136,95.2947 C149.6556,93.6597 148.8926,91.9127 148.0116,90.2317 C146.2556,86.8847 144.4686,83.5547 142.7046,80.2117 C142.4426,79.7157 142.2336,79.1917 141.9086,78.4807',
  'M82.4869,78.4565 C81.8039,79.8275 81.2709,80.9015 80.7339,81.9735 C78.5159,86.4085 76.3149,90.8515 74.0569,95.2665 C73.7399,95.8845 73.2459,96.5645 72.6559,96.8685 C67.7829,99.3785 62.8689,101.8085 57.9649,104.2565 C57.8319,104.3225 57.6659,104.3185 57.3399,104.3815 C57.4629,103.9105 57.5169,103.5165 57.6659,103.1625 C59.5639,98.6615 61.4609,94.1605 63.4019,89.6785 C63.6209,89.1715 64.0449,88.7165 64.4659,88.3405 C67.8489,85.3155 71.2329,82.2895 74.6759,79.3325 C75.2199,78.8645 76.0469,78.5435 76.7659,78.4955 C78.5469,78.3775 80.3409,78.4565 82.4869,78.4565',
  'M87.3987,197.6487 C93.8687,203.6977 100.1277,209.5497 106.4797,215.4887 C103.8947,219.4547 101.3607,223.3437 98.6817,227.4537 C97.6557,224.4557 96.5997,221.7287 95.7907,218.9297 C94.2597,213.6277 91.0207,209.2147 88.4757,204.4407 C88.1287,203.7897 87.8077,203.1057 87.3507,202.5367 C86.1147,200.9987 86.6967,199.5127 87.3987,197.6487',
  'M136.9719,197.7562 C137.6079,199.4882 138.2359,200.9912 136.9789,202.5142 C136.7809,202.7552 136.6639,203.0622 136.4949,203.3292 C132.0579,210.3582 128.4329,217.7572 126.2089,225.7992 C126.0699,226.3002 125.8479,226.7772 125.5729,227.5072 C123.7779,224.6042 122.0799,221.8892 120.4119,219.1552 C118.1389,215.4312 118.1289,215.4032 121.2969,212.3942 C126.0769,207.8542 130.8879,203.3452 135.6909,198.8282 C136.0599,198.4802 136.4679,198.1752 136.9719,197.7562',
  'M92.5089,161.9397 C92.5089,158.8987 92.4679,156.2617 92.5559,153.6287 C92.5679,153.2687 93.1909,152.6647 93.5809,152.6207 C98.1979,152.1077 102.8229,151.6747 107.4469,151.2327 C108.1549,151.1647 108.8699,151.1187 108.8249,152.1967 C108.6359,156.6817 108.4639,161.1687 108.2769,165.6537 C108.2709,165.7927 108.1569,165.9277 108.0249,166.2127 C102.9179,164.2477 98.1909,160.9417 92.5089,161.9397',
  'M115.7516,151.1437 C118.9016,151.4377 121.7766,151.6997 124.6506,151.9757 C126.5916,152.1627 128.5366,152.3267 130.4666,152.5977 C130.9136,152.6607 131.6426,153.0927 131.6676,153.4017 C131.8876,156.1237 131.9776,158.8557 132.1256,162.0187 C126.1926,160.8457 121.6656,164.1607 116.3986,166.3497 C116.1816,161.2497 115.9726,156.3507 115.7516,151.1437',
  'M108.1543,132.6007 C108.1543,135.8267 108.1873,138.8167 108.1443,141.8057 C108.1033,144.6977 108.0693,144.7087 105.2183,145.0457 C104.2793,145.1567 103.3333,145.2137 102.3883,145.2657 C99.7563,145.4127 97.1483,145.6157 94.8283,147.0947 C94.2363,147.4727 93.5293,147.6717 92.8313,147.9717 C92.8313,145.0107 92.8123,142.2407 92.8613,139.4707 C92.8653,139.2087 93.2423,138.8357 93.5303,138.7137 C98.3683,136.6687 103.2203,134.6557 108.1543,132.6007',
  'M131.4872,147.8796 C131.3512,147.8656 131.1642,147.9026 131.0512,147.8276 C127.0672,145.1796 122.4652,145.3286 117.9872,144.9636 C117.0912,144.8896 116.4732,144.8096 116.4842,143.6846 C116.5202,140.0586 116.4972,136.4316 116.4972,132.6836 C118.5372,133.5236 120.7132,134.4036 122.8752,135.3166 C125.3982,136.3816 127.9222,137.4466 130.4122,138.5836 C130.8532,138.7846 131.4262,139.3296 131.4402,139.7326 C131.5312,142.4106 131.4872,145.0936 131.4872,147.8796',
  'M53.0417,103.5429 C53.0417,99.1719 53.0167,94.7999 53.0717,90.4299 C53.0787,89.8559 53.3777,89.2049 53.7307,88.7299 C56.1207,85.5139 58.5667,82.3399 60.9817,79.1429 C61.8837,77.9489 62.9327,77.2769 64.5517,77.4439 C67.0957,77.7069 69.6607,77.7719 72.4937,77.9369 C72.0477,78.4229 71.8127,78.7439 71.5167,78.9909 C68.0407,81.8919 64.5407,84.7629 61.0887,87.6919 C60.5527,88.1469 60.1097,88.7999 59.8257,89.4499 C57.9037,93.8409 56.0367,98.2559 54.1487,102.6629 C54.0097,102.9859 53.8507,103.3009 53.7017,103.6189 C53.4817,103.5939 53.2617,103.5679 53.0417,103.5429',
  'M170.5975,103.6425 C168.4995,98.7445 166.5575,94.1385 164.5385,89.5665 C164.1915,88.7825 163.6155,88.0275 162.9695,87.4595 C159.7595,84.6415 156.4915,81.8895 153.2405,79.1185 C152.8935,78.8225 152.5235,78.5545 152.1645,78.2735 C152.2315,78.1465 152.2975,78.0185 152.3645,77.8915 C155.4425,77.7475 158.5205,77.5765 161.5995,77.4985 C161.9515,77.4895 162.3985,77.9365 162.6625,78.2795 C165.3305,81.7475 167.9825,85.2285 170.6015,88.7355 C170.9265,89.1705 171.2135,89.7755 171.2055,90.2965 C171.1395,94.5145 171.0005,98.7315 170.8745,102.9495 C170.8695,103.0915 170.7655,103.2315 170.5975,103.6425',
  'M108.6359,118.5884 C108.3919,121.6754 108.2009,124.5084 107.9049,127.3304 C107.8689,127.6694 107.3139,128.0424 106.9189,128.2274 C106.5599,128.3964 106.0869,128.3824 105.6709,128.3574 C102.2859,128.1574 99.6409,129.8854 96.9759,131.6114 C95.5239,132.5524 94.0039,133.3864 92.2059,134.4504 C91.9589,131.8754 91.3479,129.5054 91.6379,127.2504 C91.8599,125.5214 92.8719,123.7084 94.9819,123.0324 C99.1429,121.7004 103.2719,120.2694 107.4159,118.8854 C107.7059,118.7884 108.0109,118.7384 108.6359,118.5884',
  'M116.7969,128.3464 C116.5209,124.9864 116.2689,121.9114 115.9979,118.6064 C116.6349,118.7594 117.0279,118.8214 117.3989,118.9484 C121.4299,120.3294 125.4599,121.7124 129.4839,123.1104 C131.1819,123.7014 132.8639,126.2234 132.7289,128.0304 C132.5759,130.0654 132.3529,132.0954 132.1269,134.4734 C130.1999,133.3214 128.4619,132.4524 126.9149,131.3214 C123.9579,129.1584 120.7159,128.0524 116.7969,128.3464',
  'M134.8936,308.848 C135.0336,310.167 135.2306,311.568 135.3056,312.976 C135.3316,313.455 135.2036,314.117 134.8836,314.413 C131.7086,317.351 130.4906,321.124 130.2376,325.271 C130.1196,327.209 128.8856,327.614 127.2296,327.959 C126.6336,324.954 126.1126,322.025 125.4596,319.126 C124.9766,316.979 124.3726,314.856 123.7296,312.75 C123.1036,310.698 124.1736,308.924 126.2996,308.867 C129.1236,308.791 131.9516,308.848 134.8936,308.848',
  'M96.822,327.9163 C95.22,327.6493 94.088,326.9623 94.038,325.0133 C94.006,323.7633 93.522,322.5283 93.464,321.2763 C93.351,318.8373 91.873,317.1243 90.308,315.6343 C88.163,313.5913 89.303,311.3433 89.47,309.1443 C89.487,308.9253 90.282,308.5973 90.712,308.6033 C93.241,308.6433 95.769,308.7493 98.296,308.8683 C99.787,308.9383 101.154,310.6763 100.801,312.1013 C99.505,317.3453 98.169,322.5783 96.822,327.9163',
  'M149.8287,188.2894 C149.9707,192.3254 150.1287,196.2144 150.2387,200.1044 C150.3977,205.6924 150.4827,211.2844 150.6887,216.8714 C150.7387,218.2184 150.4597,218.7624 148.9887,218.6854 C146.0157,218.5284 146.4487,219.0134 146.3007,216.0764 C145.9807,209.7214 145.5747,203.3714 145.2027,197.0194 C145.1747,196.5454 145.1317,196.0724 145.1017,195.5984 C144.7977,190.7644 144.9717,190.4864 149.8287,188.2894',
  'M109.0932,218.9519 C109.2232,222.4649 109.1592,225.9989 109.5312,229.4869 C109.9972,233.8619 109.1542,237.8019 106.7712,241.4859 C105.9362,242.7769 105.3582,244.2339 104.6622,245.6149 C104.4872,245.6089 104.3122,245.6019 104.1362,245.5959 C103.3702,241.6689 102.6032,237.7419 101.8372,233.8139 C101.7972,233.6069 101.8072,233.3819 101.7302,233.1919 C100.5392,230.2629 102.2192,228.2479 103.8612,226.1509 C104.9912,224.7089 105.9242,223.1129 106.9382,221.5809 C107.5422,220.6689 108.1322,219.7469 108.7292,218.8299 C108.8502,218.8699 108.9722,218.9109 109.0932,218.9519',
  'M139.1273,153.2409 C138.9783,151.0929 138.8033,148.9469 138.7023,146.7969 C138.6853,146.4259 138.8853,145.9529 139.1443,145.6759 C143.7083,140.7959 147.2503,135.1959 150.6343,129.4819 C150.7403,129.3029 150.8213,129.1089 150.9143,128.9219 C151.1153,128.9779 151.3163,129.0349 151.5173,129.0909 C151.5423,130.7169 151.7983,132.3799 151.5413,133.9609 C151.0263,137.1309 150.4493,140.3259 149.4603,143.3689 C148.9423,144.9629 147.5943,146.3379 146.4493,147.6629 C145.1823,149.1289 143.7683,150.4809 142.3213,151.7739 C141.5053,152.5019 140.4943,153.0119 139.5713,153.6199 C139.4233,153.4939 139.2753,153.3669 139.1273,153.2409',
  'M73.926,218.5273 C74.21,208.4353 74.49,198.4643 74.783,188.0583 C76.427,189.1543 77.737,189.9873 78.99,190.8983 C79.221,191.0653 79.33,191.5513 79.316,191.8833 C78.963,200.7253 78.587,209.5653 78.21,218.5273 L73.926,218.5273 Z',
  'M115.9399,218.8339 C116.8939,220.3289 117.8489,221.8229 118.8029,223.3179 C118.9169,223.4959 119.0319,223.6729 119.1439,223.8519 C120.4519,225.9569 122.4349,227.9299 122.8759,230.2029 C123.3169,232.4749 122.2229,235.0469 121.7879,237.4849 C121.3059,240.1809 120.8089,242.8749 120.2599,245.8879 C119.8959,245.3379 119.6539,245.0399 119.4849,244.7059 C118.7219,243.1989 118.0929,241.6129 117.2059,240.1849 C115.4119,237.2959 114.7559,234.2429 115.0349,230.8459 C115.3589,226.9099 115.3859,222.9499 115.5419,218.9999 C115.6749,218.9449 115.8069,218.8889 115.9399,218.8339',
  'M85.115,153.7044 C80.748,151.7234 78.648,147.7274 75.551,144.8344 C74.541,139.6284 72.256,134.5954 73.609,128.9454 C73.931,129.4304 74.273,129.9034 74.572,130.4024 C77.743,135.6954 80.721,141.1194 85.08,145.5974 C85.427,145.9544 85.633,146.6224 85.611,147.1324 C85.52,149.1834 85.313,151.2294 85.115,153.7044',
  'M98.1493,49.1591 C98.5423,49.7081 98.9303,50.2611 99.3303,50.8061 C101.2283,53.3921 103.1083,55.9921 105.0383,58.5551 C106.2653,60.1841 107.0633,61.8831 107.2713,64.0001 C107.7453,68.8141 108.4913,73.6021 109.1263,78.4011 C108.9633,78.5301 108.8003,78.6581 108.6373,78.7861 C108.5673,78.5331 108.5503,78.2481 108.4183,78.0331 C105.6653,73.5521 102.8533,69.1061 100.1753,64.5811 C99.6153,63.6351 99.4143,62.4171 99.2723,61.2951 C98.7613,57.2771 98.3473,53.2461 97.8963,49.2201 C97.9803,49.2001 98.0653,49.1791 98.1493,49.1591',
  'M173.2666,146.8693 C173.6066,144.7223 173.9326,142.5733 174.2906,140.4293 C174.7416,137.7323 175.4576,135.0533 175.6026,132.3413 C175.7036,130.4563 175.0836,128.5203 174.7036,126.6233 C173.8536,122.3893 172.9516,118.1653 172.0766,113.9363 C172.0156,113.6423 172.0036,113.3373 171.9116,112.5473 C172.5106,113.3083 172.8336,113.6843 173.1186,114.0853 C175.0416,116.7973 176.9906,119.4913 178.8496,122.2463 C179.2616,122.8563 179.5406,123.6853 179.5496,124.4173 C179.5996,128.4283 179.6046,132.4423 179.4866,136.4513 C179.4576,137.4353 179.0856,138.5133 178.5666,139.3613 C176.9806,141.9503 175.2456,144.4483 173.5696,146.9823 C173.4686,146.9443 173.3676,146.9073 173.2666,146.8693',
  'M115.5317,78.2936 C116.2637,72.8076 116.9937,67.3206 117.7307,61.8356 C117.7647,61.5766 117.7827,61.2786 117.9217,61.0756 C120.6247,57.1256 123.3447,53.1866 126.3917,49.3606 C126.1377,51.7326 125.8937,54.1066 125.6247,56.4766 C125.4937,57.6276 125.1617,58.7776 125.1897,59.9206 C125.2607,62.7756 123.9827,65.0736 122.5437,67.3856 C120.5347,70.6116 118.6567,73.9196 116.7157,77.1886 C116.4567,77.6246 116.1577,78.0366 115.8767,78.4596 C115.7617,78.4046 115.6467,78.3486 115.5317,78.2936',
  'M50.6389,146.8314 C48.8189,144.0644 46.9819,141.3084 45.1979,138.5194 C44.9629,138.1534 44.9289,137.6164 44.9189,137.1564 C44.8269,132.9894 44.7229,128.8214 44.7219,124.6544 C44.7219,123.8744 44.9949,122.9794 45.4329,122.3344 C47.5819,119.1634 49.8299,116.0594 52.4019,113.0654 C52.1369,114.4124 51.8849,115.7634 51.6039,117.1084 C50.6669,121.5904 49.4889,126.0384 48.8729,130.5644 C48.5639,132.8304 49.2029,135.2454 49.5379,137.5744 C49.9789,140.6324 50.5429,143.6734 51.0539,146.7214 C50.9159,146.7584 50.7769,146.7944 50.6389,146.8314',
  'M100.0143,339.6142 C99.7243,337.6552 99.4333,335.6962 99.1443,333.7362 C99.0603,333.1652 99.0013,332.5882 98.8913,332.0212 C98.5733,330.3842 98.8113,328.9322 99.5693,327.3582 C101.8633,322.5902 105.1923,318.3402 106.6683,313.1652 C106.9533,312.1662 107.3703,311.2052 108.0443,310.2482 C108.1003,310.7832 108.1703,311.3172 108.2083,311.8532 C108.2933,313.0632 108.2653,314.2882 108.4553,315.4802 C109.0653,319.3052 107.9123,322.5962 106.1003,325.9602 C104.1253,329.6282 102.7933,333.6432 101.1863,337.5092 C100.8923,338.2182 100.6273,338.9402 100.3493,339.6562 C100.2373,339.6422 100.1263,339.6282 100.0143,339.6142',
  'M123.9738,339.7042 C123.4508,338.3032 122.8888,336.9162 122.4108,335.5002 C120.8268,330.8012 119.0348,326.1962 116.5168,321.9012 C116.2838,321.5032 116.0398,321.0192 116.0538,320.5832 C116.1678,317.0782 116.3428,313.5742 116.8268,310.0042 C117.0948,310.7162 117.4118,311.4142 117.6228,312.1412 C119.1378,317.3492 121.8438,322.0112 124.4148,326.7162 C125.4668,328.6412 125.7788,330.4672 125.3788,332.5682 C124.9358,334.8892 124.6618,337.2412 124.3148,339.5802 C124.2008,339.6212 124.0878,339.6632 123.9738,339.7042',
  'M122.069,72.5239 C123.759,69.4469 125.345,66.5609 127.022,63.5089 C132.487,66.4599 137.844,69.3529 143.201,72.2459 C143.181,72.3389 143.161,72.4309 143.141,72.5239 L122.069,72.5239 Z',
  'M97.2847,63.5098 C98.9457,66.5098 100.5457,69.3998 102.2697,72.5158 L81.1727,72.5158 C81.1297,72.4428 81.0867,72.3698 81.0447,72.2968 C86.4207,69.3878 91.7967,66.4788 97.2847,63.5098',
  'M51.1881,156.7518 C50.0241,155.1558 50.0701,154.0608 51.4121,152.7548 C54.6261,149.6278 57.6411,146.2978 60.7721,143.0838 C61.6781,142.1538 62.7041,141.3418 63.9821,140.2018 C63.5731,142.5028 63.1441,144.4558 62.8991,146.4308 C62.6841,148.1598 61.9561,149.2888 60.4141,150.2608 C57.2571,152.2498 54.2921,154.5418 51.1881,156.7518',
  'M160.6789,140.5782 C162.2179,142.0112 163.8459,143.4302 165.3619,144.9592 C167.8889,147.5102 170.3439,150.1322 172.8319,152.7222 C174.4579,154.4142 174.4599,154.4122 173.1439,156.7532 C171.2109,155.3792 169.4029,153.8082 167.3479,152.6922 C163.4299,150.5652 161.1459,147.5722 161.1399,142.9962 C161.1389,142.1732 160.8339,141.3502 160.6789,140.5782',
  'M82.1719,319.5346 C82.3339,314.1646 82.5009,308.6506 82.6669,303.1376 C82.7729,303.1096 82.8779,303.0806 82.9829,303.0526 C85.2349,309.2306 87.4859,315.4086 89.7739,321.6876 C87.2459,320.9716 84.8689,320.2986 82.1719,319.5346',
  'M141.6169,303.1408 C141.8039,308.6668 141.9919,314.1928 142.1739,319.5608 C139.5889,320.2708 137.1869,320.9308 134.5749,321.6478 C136.8369,315.4138 139.0719,309.2568 141.3069,303.0998 C141.4099,303.1128 141.5139,303.1268 141.6169,303.1408',
  'M138.9652,140.7989 C138.6242,137.8529 138.2992,135.4009 138.0972,132.9389 C138.0662,132.5599 138.5072,131.9839 138.8852,131.7549 C141.0082,130.4709 143.1162,129.1319 145.3582,128.0899 C146.4832,127.5669 147.8822,127.6319 149.1572,127.4309 C149.2522,127.5849 149.3482,127.7379 149.4442,127.8919 C146.0342,132.0919 142.6242,136.2919 138.9652,140.7989',
  'M75.5717,127.3055 C76.9237,127.6705 78.3607,127.8535 79.6077,128.4415 C81.5557,129.3605 83.4067,130.5015 85.2257,131.6645 C85.6907,131.9615 86.1737,132.7105 86.1427,133.2215 C85.9967,135.6205 85.6747,138.0095 85.3577,140.9295 C81.8027,136.3785 78.5317,132.1905 75.2607,128.0025 C75.3637,127.7705 75.4677,127.5375 75.5717,127.3055',
  'M86.7682,130.682 C84.3852,127.948 82.1422,125.581 80.1702,123.007 C79.4552,122.074 79.3502,120.655 79.0712,119.435 C79.0402,119.301 79.8672,118.737 80.0212,118.83 C82.7252,120.454 85.3912,122.14 88.0432,123.847 C88.2402,123.974 88.4102,124.398 88.3542,124.626 C87.8852,126.546 87.3612,128.453 86.7682,130.682',
  'M137.5628,130.6468 C136.9808,128.4968 136.4398,126.6948 136.0408,124.8618 C135.9618,124.4968 136.3098,123.8408 136.6548,123.6128 C139.0738,122.0158 141.5368,120.4858 144.0048,118.9648 C144.2758,118.7978 144.6698,118.8308 145.0078,118.7698 C145.0708,119.1508 145.2438,119.5508 145.1798,119.9088 C144.8518,121.7498 144.1598,123.3738 142.7778,124.7598 C141.0088,126.5358 139.4388,128.5088 137.5628,130.6468',
  'M150.7091,117.3984 C151.0661,119.5364 151.4241,121.3894 151.6411,123.2584 C151.6801,123.5914 151.2121,124.1994 150.8521,124.3284 C148.8841,125.0344 146.8771,125.6314 144.7221,126.0974 C146.4311,123.1214 147.2121,119.5854 150.7091,117.3984',
  'M73.9516,117.5122 C77.5626,119.3752 77.9826,123.0822 79.5596,126.2112 C77.4996,125.5512 75.4326,124.9122 73.3916,124.1992 C73.1576,124.1172 72.8976,123.5962 72.9386,123.3222 C73.2276,121.4112 73.5936,119.5122 73.9516,117.5122',
  'M143.7729,114.5349 C145.8359,112.9039 147.6239,111.4909 149.7069,109.8439 C150.0709,111.6629 150.6499,113.2419 150.5489,114.7749 C150.5139,115.2969 148.7349,116.1289 147.8069,116.0619 C146.5329,115.9689 145.3099,115.1569 143.7729,114.5349',
  'M80.4862,114.5032 C78.4502,115.4252 76.8532,116.8762 74.7802,115.6172 C74.4162,115.3962 73.9592,114.8472 74.0062,114.5162 C74.2232,113.0192 74.6042,111.5462 74.9852,109.7942 C76.8992,111.4322 78.6002,112.8882 80.4862,114.5032',
  'M80.4167,116.9396 C81.4657,116.0286 82.2447,115.3516 83.0597,114.6436 C85.2537,115.9416 87.3737,117.1966 89.5357,118.4766 C88.8967,119.8876 88.0817,120.3206 86.6587,119.6336 C84.7277,118.7016 82.7217,117.9246 80.4167,116.9396',
  'M143.8737,116.9635 C141.3217,118.0375 139.0477,119.1005 136.6847,119.9015 C136.1897,120.0695 135.3667,119.2665 134.3627,118.7355 C136.7667,117.3085 138.7177,116.0915 140.7407,115.0095 C141.0627,114.8375 141.7687,115.1735 142.1877,115.4445 C142.7387,115.8005 143.1817,116.3245 143.8737,116.9635',
].map((d, i) => {
  const commands = parseSVG(d);
  const avgPos = {
    x: sumBy(commands, 'x') / commands.length,
    y: sumBy(commands, 'y') / commands.length,
  };
  return {
    d,
    avgPos,
    commands,
  };
});

class BodyDiagramComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this._size = {
      x: 224,
      y: 441,
    };
    this.state = {
      colors: this._getColors(),
    };
  }

  componentWillMount() {
    this._startEpoch = Date.now();
    this._updateColors();
  }

  _updateColors() {
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      this.setState({ colors: this._getColors() });
      this._updateColors();
    }, 20);
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  _getColors() {
    const duration = 1500;
    const epoch = Date.now();
    const elapsed = (epoch - this._startEpoch) % duration;
    const spectrum = chroma.scale(['yellow', 'red', 'black']).domain([1, -0.5]);
    return paths.map(({ avgPos }, i) => {
      const colorInterpolation = 1 - avgPos.y / this._size.y;

      const x = (elapsed % duration) / duration;
      return spectrum(
        Math.max(0, Math.min((colorInterpolation + x) % 1, 1)),
      ).hex();
    });
  }

  render() {
    const { colors } = this.state;
    return (
      <svg
        viewBox="0 0 224 441"
        style={{
          width: 'auto',
          height: '100%',
          display: 'flex',
          flex: '1 1 auto',
        }}
      >
        <g scale={1}>
          {paths.map(({ d }, i) => (
            <path key={i} d={d} fill={colors[i]} />
          ))}
        </g>
      </svg>
    );
  }
}

export default BodyDiagramComponent;
