import PropTypes from 'prop-types';
import { uniq, sortBy } from 'lodash'
import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import styles from './WeightPicker.module.scss';
import Picker from "../Picker/Picker";
const PickerItem = Picker.Item

class WeightPicker extends Component {

  static getWeightOptions = (additionalWeights, minWeightLbs=0) => {
    const maxWeightLbs = 1000
    const weightIncrementsLbs = 5
    const weightOptionCount = Math.floor(maxWeightLbs / weightIncrementsLbs) - 1;
    return uniq(sortBy([
      ...new Array(9).fill(0).map((_, i) => i + 1),
      ...new Array(weightOptionCount).fill(0).map((_, i) => (i + 1) * weightIncrementsLbs + weightIncrementsLbs),
      ...additionalWeights,
    ])).filter(x => x >= minWeightLbs);
  }

  constructor(props) {
    super(props)
    const weightOptions = WeightPicker.getWeightOptions([props.value], props.minWeightLbs);
    this.state = {
      weightOptions,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      weightOptions: WeightPicker.getWeightOptions([nextProps.value], nextProps.minWeightLbs)
    })
  }

  render () {
    const { style, itemStyle, value, onValueChange } = this.props
    const weightUnitLabel = 'lb';

    return (
      <Picker
        style={style}
        selectedValue={value}
        itemStyle={itemStyle}
        onValueChange={onValueChange}
      >
        {this.state.weightOptions.map(value => (
          <PickerItem
            label={`${value} ${weightUnitLabel}${value !== 1 ? 's' : ''}`}
            value={value}
            key={'weight-' + value}
          />
        ))}
      </Picker>
    )
  }
}

export default WeightPicker
