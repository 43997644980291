import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setActivePageId: ['activePageId'],
})

export const UIStateTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  activePageId: 'home'
})

/* ------------- Reducers ------------- */

const setActivePageId = (state, { activePageId }) => {
  return state.merge({ activePageId })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_PAGE_ID]: setActivePageId,
})

/* ------------- Selectors ------------- */
