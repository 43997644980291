import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import NavigationActions from '../../Redux/NavigationRedux';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import activeWorkoutSelector from '../../Selectors/activeWorkoutSelector';
import ExerciseActions from '../../Redux/ExerciseStore';
import styles from './WorkoutDetailScreen.module.scss';
import ScreenTitleBackButton from '../../components/ScreenTitleButtons/ScreenTitleBackButton';
import { ScreenTitleEditButton } from '../../components/ScreenTitleButtons/ScreenTitleBackButton';

class WorkoutDetailScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  _selectExercise(exercise) {
    this.props.pushScreen({
      id: 'exerciseDetail',
      exerciseId: exercise.id,
    });
  }

  _renderExerciseRow(exercise) {
    return (
      <div
        key={exercise.id}
        className={styles.row}
        onClick={() => this._selectExercise(exercise)}
      >
        <div key={exercise.id} className={styles.name}>
          <div className={styles.nameInner}>{exercise.name}</div>
        </div>
      </div>
    );
  }

  _renderBody() {
    const { workout } = this.props;
    const { name, exercises = [] } = workout;
    const setsPerExercise = 3; // TODO: Make configurable
    const setCount = exercises.length * setsPerExercise;
    const durationMins = Math.round((setCount * (60 + 90)) / 60);

    return (
      <div className={styles.root} ref={(el) => (this._scrollViewEl = el)}>
        <div className={styles.titleSection}>
          <div className={styles.workoutName}>{name}</div>
          <div className={styles.workoutDetails}>
            {exercises.length} exercises
            {` ∙ `}
            {setCount} sets
            {` ∙ `}
            {durationMins} mins
          </div>
        </div>

        <div className={styles.rows}>
          {exercises.map((exercise) => this._renderExerciseRow(exercise))}
        </div>
      </div>
    );
  }

  render() {
    const { workout } = this.props;
    const { name } = workout;
    return (
      <ScreenLayoutComponent
        title={name}
        body={this._renderBody()}
        leftNode={<ScreenTitleBackButton />}
        rightNode={
          <ScreenTitleEditButton
            onClick={() =>
              this.props.pushScreen({
                id: 'workoutUpdate',
                workoutId: workout.id,
              })
            }
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  const workout = activeWorkoutSelector(state);
  return {
    workout,
    user: state.login.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popScreen: () => dispatch(NavigationActions.popScreen()),
    pushScreen: (screen) => dispatch(NavigationActions.pushScreen(screen)),
    setSelectedExerciseId: (exerciseId) =>
      dispatch(ExerciseActions.setSelectedExerciseId(exerciseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkoutDetailScreen);
