import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import NavigationActions from '../../Redux/NavigationRedux';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import styles from './ExportCSVScreen.module.scss';
import { exportAsCSV } from '../../Redux/ExportRedux';
import ScreenTitleBackButton from "../../components/ScreenTitleButtons/ScreenTitleBackButton";

class ExportCSVScreen extends Component {
  state = {
    csvString: '',
  };

  componentWillMount() {
    const csvString = this.props.exportAsCSV();
    console.log(`csvString`, csvString);
    this.setState({ csvString });
  }

  _onFocus = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const range = document.createRange(); // create new range object
    range.selectNodeContents(this._textareaEl); // set range to encompass desired element text
    const selection = window.getSelection(); // get Selection object from currently user selected text
    selection.removeAllRanges(); // unselect any user selected text (if any)
    selection.addRange(range); // add range to Selection object to select it

    this._textareaEl.focus();
    this._textareaEl.select();
  };

  _renderBody() {
    const { csvString } = this.state;
    return (
      <div className={styles.root}>
        <div className={styles.title}>Your CSV is ready!</div>
        <div className={styles.subtitle}>Copy it from the text box below</div>
        <div className={styles.export}>
          <textarea
            readOnly
            ref={(el) => (this._textareaEl = el)}
            className={styles.textarea}
            onFocus={this._onFocus}
            defaultValue={csvString}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <ScreenLayoutComponent
        title={'Export CSV'}
        body={this._renderBody()}
        leftNode={<ScreenTitleBackButton />}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    popScreen: () => dispatch(NavigationActions.popScreen()),
    pushScreen: (screen) => dispatch(NavigationActions.pushScreen(screen)),
    exportAsCSV: () => dispatch(exportAsCSV()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportCSVScreen);
