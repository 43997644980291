import './Config'
import { Provider } from 'react-redux'
import React, { Component } from 'react';
import createStore from './Redux'
import FirebaseService from "./Services/FirebaseService";
import styles from './App.module.scss';
import { PersistGate } from 'redux-persist/integration/react'
import LiftLogComponent from "./components/LiftLogComponent/LiftLogComponent";

// create our store
const { store, persistor } = createStore()
const firebase = FirebaseService.create()

class App extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LiftLogComponent/>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
