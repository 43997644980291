import styles from "./formStyles.module.scss";
import React from "react";

const FormLabel = (props) => {
  return (
    <label className={styles.label}>{props.children}</label>
  )
};

export default FormLabel;
