import { pick } from 'lodash'
import firebase from 'firebase'
import generatePushID from '../Lib/FirebaseHelpers/generatePushID'

var config = {
    apiKey: "AIzaSyDEjLO7DgipZVq3rfTzFmDSP6-vLDJYeN4",
    authDomain: "liftlog-dev.firebaseapp.com",
    databaseURL: "https://liftlog-dev.firebaseio.com",
    projectId: "liftlog-dev",
    storageBucket: "liftlog-dev.appspot.com",
    messagingSenderId: "1018239651643"
};

const __DEV__ = process.env.NODE_ENV !== 'production';
const getPath = (path) => {
  const prefix = __DEV__ ? 'dev' : 'prod'
  return `${prefix}/${path}`
}

const usersPath = getPath('users')
const workoutsPath = getPath('workouts')
const exercisesPath = getPath('exercises')

const getExercisesPath = (userId) => `${usersPath}/${userId}/sets`
const profilePath = ({usersPath, userId}) => `${usersPath}/${userId}/profile`;

let app;
let db;
function create() {
  if (!app) {
    app = firebase.initializeApp(config);
    db = app.database();
    // app.on('debug', msg => console.log('Received debug message', msg))
  }

  const _getNewItem = (itemProps) => {
    const id = generatePushID()
    const itemData = {
      ...itemProps,
      id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    return itemData
  }

  const _pushItem = ({item, itemRefPath}) => {
    const itemData = {
      ...item,
      id: item.id || generatePushID(),
    };
    return db
      .ref(`${itemRefPath}/${itemData.id}`)
      .set(itemData)
      .then(() => Promise.resolve(itemData))
  }

  const _updateItem = ({itemProps, itemRefPath}) => {
    const itemData = {
      ...itemProps,
      updatedAt: Date.now(),
    };
    return db
      .ref(`${itemRefPath}/${itemProps.id}`)
      .set(itemData)
      .then(() => Promise.resolve(itemData))
  }

  const _createOrUpdateItem = ({itemProps, itemRefPath}) => {
    const id = itemProps.id || generatePushID()
    const itemData = {
      ...itemProps,
      id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    return db
      .ref(`${itemRefPath}/${id}`)
      .set(itemData)
      .then(() => Promise.resolve(itemData))
  }

  const updateExercise = (exercise) => {
    return _createOrUpdateItem({
      itemRefPath: exercisesPath,
      itemProps: exercise,
    })
  }

  const createWorkout = (workout) => {
    return _createOrUpdateItem({
      itemRefPath: workoutsPath,
      itemProps: workout,
    })
  }

  const updateWorkout = (workout) => {
    return _createOrUpdateItem({
      itemRefPath: workoutsPath,
      itemProps: workout,
    })
  }

  const destroyExercise = (exercise) => {
    console.log(`destroying ${exercise.id}`);
    return db.ref(`${exercisesPath}/${exercise.id}`).remove()
  }

  const destroyWorkout = (workout) => {
    console.log(`destroying ${workout.id}`);
    return db.ref(`${workoutsPath}/${workout.id}`).remove()
  }

  const destroyExerciseSet = ({userId, exerciseSetId}) => {
    return db.ref(`${getExercisesPath(userId)}/${exerciseSetId}`).remove()
  }

  const buildExerciseSet = (exerciseSet) => _getNewItem(exerciseSet)

  const createExerciseSet = ({userId, exerciseSet}) => {
    return _pushItem({
      itemRefPath: getExercisesPath(userId),
      item: exerciseSet,
    })
  }

  const getExercises = () => {
    return db
      .ref(exercisesPath)
      .once('value')
      .then((snapshot) => Object.values(snapshot.val()))
  }

  const getWorkouts = () => {
    return db
      .ref(workoutsPath)
      .once('value')
      .then((snapshot) => Object.values(snapshot.val()))
  }

  const getExerciseSets = ({userId}) => {
    return db
      .ref(getExercisesPath(userId))
      .once('value')
      .then((snapshot) => Object.values(snapshot.val()))
  }

  const signup = (username, password) => {
    return app.auth().createUserWithEmailAndPassword(username, password)
  }

  const login = (username, password) => {
    return app.auth().signInWithEmailAndPassword(username, password)
  }

  const getUserProfile = (userId) => {
    return db
      .ref(profilePath({usersPath, userId}))
      .once('value')
      .then((snapshot) => ({ id: userId, profile: snapshot.val() }))
  }

  const updateUserProfile = (userId, profileData) => {
    return db
      .ref(profilePath({usersPath, userId}))
      .set(profileData)
  }

  return {
    login,
    signup,
    getUserProfile,
    updateUserProfile,
    //
    createWorkout,
    updateWorkout,
    getWorkouts,
    getExercises,
    getExerciseSets,
    updateExercise,
    destroyExercise,
    destroyWorkout,
    buildExerciseSet,
    createExerciseSet,
    destroyExerciseSet,
  }
}


export default {
  create
}
