import { createSelector } from 'reselect';

const activeWorkoutSelector = createSelector(
  (state) => state.exercise,
  (exercise) => {
    const { activeWorkoutId, workouts, exercises } = exercise;
    const workout = workouts.find((workout) => workout.id === activeWorkoutId);
    if (!workout) return null;

    const { exerciseIds = [] } = workout;
    return {
      ...workout,

      // Popuplate the exercises
      exercises: exerciseIds.map((exerciseId) =>
        exercises.find(({ id }) => id === exerciseId),
      ),
    };
  },
);

export default activeWorkoutSelector;
