import { createSelector } from 'reselect';
import last from 'lodash/last'

const selectedExerciseSelector = createSelector(
  state => state.exercise,
  state => state.navigation,
  (exercise, navigation) => {
    const { exercises } = exercise;
    const { screenStack = [] } = navigation;
    const topScreen = last(screenStack)
    return topScreen && exercises
      .find(({ id }) => id === topScreen.exerciseId)
  }
)

export default selectedExerciseSelector
