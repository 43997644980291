import React from 'react';
import chroma from 'chroma-js';
import { connect } from 'react-redux';
import Sugar from 'sugar-date';
import WeightPicker from '../../components/WeightPicker/WeightPicker';
import ExerciseLogListComponent from '../../components/ExerciseLogListComponent/ExerciseLogListComponent';
import FirebaseService from '../../Services/FirebaseService';
import ExerciseActions from '../../Redux/ExerciseStore';
import { filter, sortBy, padStart, last } from 'lodash';
import oneRepMax from 'one-rep-max';
import { Colors } from '../../Themes/index';
import styles from './ExerciseDetailScreen.module.scss';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Picker from '../../components/Picker/Picker';
import NewRecordOverlay from '../../components/NewRecordOverlay/NewRecordOverlay';
import NavigationActions from '../../Redux/NavigationRedux';
import selectedExerciseSelector from '../../Selectors/selectedExerciseSelector';
import ScreenTitleBackButton from '../../components/ScreenTitleButtons/ScreenTitleBackButton';
import { ScreenTitleEditButton } from '../../components/ScreenTitleButtons/ScreenTitleBackButton';

const PickerItem = Picker.Item;

const totalTime = 4 * 60 * 1000; // 4 min

const firebase = FirebaseService.create();

class ExerciseDetailScreen extends React.Component {
  static userColors = [Colors.fire, Colors.materialBlue];

  constructor(props) {
    super(props);

    // Look up the last set or use a default
    const lastSet = (props.setsPast.length > 0 &&
      props.setsPast[props.setsPast.length - 1]) || {
      repCount: 7,
      weightLbs: 10,
    };
    const { weightLbs, repCount } = lastSet;

    const users = [
      {
        id: props.user.id,
        name: props.user.profile.firstName,
      },
    ];

    this.state = {
      users,
      selectedUserIndex: 0,
      repCount,
      weightLbs,
      inputFocused: false,
      showingNewRecord: false,
      showingWarmUpModal: false,
      repOptions: [...new Array(30).fill(0).map((_, i) => i + 1)],
    };
    this.state = { ...this.state, ...this._getSyncedRawTexts() };
  }

  componentDidMount() {
    this._timerInterval = setInterval(this.forceUpdate.bind(this), 500);
    this._startTimer(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this._startTimer(nextProps);
  }

  _getPercentRestComplete(props) {
    const { mostRecentExerciseSetAny } = props;
    if (!mostRecentExerciseSetAny) {
      return 1;
    }
    const secondsSince = Sugar.Date.secondsSince(
      new Date(),
      mostRecentExerciseSetAny.createdAt,
    );
    return Math.min((secondsSince * 1000) / totalTime, 1);
  }

  _startTimer(props) {
    const { mostRecentExerciseSetAny } = props;
    if (!mostRecentExerciseSetAny) {
      return;
    }

    const percent = this._getPercentRestComplete(props);
    const duration = (1 - percent) * totalTime;

    const { clientWidth } = document.body.clientWidth;
    const totalWidth = clientWidth - 4 * 2;
    // const timerBarWidth = new Animated.Value(totalWidth * percent);
    // this.setState({ timerBarWidth }, () => {
    //   this._timerBarAnimationTimer = Animated.timing(
    //     timerBarWidth,
    //     {
    //       duration,
    //       toValue: totalWidth,
    //     }
    //   );
    //   this._timerBarAnimationTimer.start();
    // });
  }

  componentWillUnmount() {
    clearInterval(this._timerInterval);
    // if (this._timerBarAnimationTimer) this._timerBarAnimationTimer.stop();
  }

  _onChangeWeightPicker(weightLbs) {
    this.setState({ weightLbs }, () => {
      this.setState(this._getSyncedRawTexts());
    });
  }

  _onChangeRepPicker(repCount) {
    this.setState({ repCount }, () => {
      this.setState(this._getSyncedRawTexts());
    });
  }

  _getSyncedRawTexts() {
    const { repCount, weightLbs, rawInputTexts } = this.state;
    return {
      rawInputTexts: {
        ...rawInputTexts,
        weightLbs: `${weightLbs}`,
        repCount: `${repCount}`,
      },
    };
  }

  _recordNewSet() {
    const { user, exercise, mostRecentExerciseSetAny } = this.props;
    const { repCount, weightLbs, users, selectedUserIndex } = this.state;

    const exerciseSet = firebase.buildExerciseSet({
      repCount,
      weightLbs,
      doneByUserId: users[selectedUserIndex].id,
      createdByUserId: user.id,
      exerciseId: exercise.id,
      exerciseName: exercise.name,
    });
    this.props.createExerciseSet(exerciseSet);

    firebase.createExerciseSet({
      exerciseSet,
      userId: user.id,
    });
    this._logList.scrollToTop();

    if (!mostRecentExerciseSetAny) return;
    const thisORM = calculateOneRepMax(exerciseSet);
    const lastORM = calculateOneRepMax(mostRecentExerciseSetAny);

    if (thisORM > lastORM) {
      this.setState({ showingNewRecord: true });
    }
  }

  _setInputFocused(inputFocused) {
    clearTimeout(this._blurTimer);
    this.setState({ inputFocused });
  }

  _doneEditing() {
    this.setState({ inputFocused: false });
  }

  _onChangeRawInputText(prop, rawText) {
    const value = parseInt(rawText, 10);
    if (!isNaN(value) && value > 0) {
      this.setState({ [prop]: value });
    }
    this.setState({
      rawInputTexts: {
        ...this.state.rawInputTexts,
        [prop]: rawText,
      },
    });
  }

  _changeSelectedUser(userName) {
    const { users } = this.state;
    const selectedUserIndex = users.findIndex((user) => user.name === userName);
    this.setState({ selectedUserIndex });
  }

  _getSelectedUser() {
    const { users, selectedUserIndex } = this.state;
    return users[selectedUserIndex];
  }

  _getSelectedUserColor() {
    const { selectedUserIndex } = this.state;
    return ExerciseDetailScreen.userColors[selectedUserIndex];
  }

  _renderUserSwitcher() {
    const { users, selectedUserIndex } = this.state;
    const userNames = users.map((user) => user.name);
    return null;
    // return (
    {
      /*<div className={styles.userSwitcherRow}>*/
    }
    {
      /*<SegmentedControlIOS*/
    }
    // values={userNames}
    // tintColor={this._getSelectedUserColor()}
    // selectedIndex={selectedUserIndex}
    // onValueChange={(userName) => this._changeSelectedUser(userName)}
    // />
    // </div>
    // )
  }

  _syncWeightToORM() {
    const { setsPast } = this.props;
    const { repCount } = this.state;

    // Given the repCount, increase or decrease the weightLbs until you find a matching
    // ORM to the last set

    const selectedUser = this._getSelectedUser();

    const exerciseSet = setsPast
      .slice()
      .reverse()
      .find((set) => set.doneByUserId === selectedUser.id);
    if (!exerciseSet) return;

    const currentORM = calculateOneRepMax(exerciseSet);
    const weightOptions = WeightPicker.getWeightOptions([
      exerciseSet.weightLbs,
    ]);
    const currentWeightIndex = weightOptions.indexOf(exerciseSet.weightLbs);
    const weightIndexDelta = repCount < exerciseSet.repCount ? 1 : -1;
    const testFn =
      repCount < exerciseSet.repCount
        ? (testOrm, currentORM) => testOrm < currentORM
        : (testOrm, currentORM) => testOrm > currentORM;

    let testOrm;
    let testWeightLbs;
    let weightIndex = currentWeightIndex;
    do {
      weightIndex += weightIndexDelta;
      testWeightLbs = weightOptions[weightIndex];
      testOrm = calculateOneRepMax({ weightLbs: testWeightLbs, repCount });
    } while (testFn(testOrm, currentORM));

    this.setState({
      weightLbs: testWeightLbs || weightOptions[weightOptions.length - 1],
    });
  }

  _undoLastSet() {
    const { setsPast, user } = this.props;
    const exerciseSet = setsPast[setsPast.length - 1];
    FirebaseService.create()
      .destroyExerciseSet({ userId: user.id, exerciseSetId: exerciseSet.id })
      .then(() => {
        this.props.destroyExerciseSet(exerciseSet.id);
      });
  }

  _showWarmUpModal = () => {
    this.setState({ showingWarmUpModal: true });
  };

  _renderWeightBreakdownBar() {
    const { exercise } = this.props;
    const { weightLbs } = this.state;

    const plateWeightLbs = calcPlateWeight(weightLbs, exercise);
    if (plateWeightLbs === weightLbs) return null;

    return (
      <div
        className={styles.oneRepMaxRow}
        onClick={() => this._showWarmUpModal(weightLbs)}
      >
        {this._renderWeightBreakdownText(weightLbs)}
      </div>
    );
  }

  _renderWeightBreakdownText(weightLbs) {
    const { exercise } = this.props;
    const plateWeightLbs = calcPlateWeight(weightLbs, exercise);

    let displayWeightLbs = plateWeightLbs;
    const roundedWeightLbs = Math.round(plateWeightLbs);
    if (roundedWeightLbs !== plateWeightLbs) displayWeightLbs = plateWeightLbs;

    return (
      <div className={styles.oneRepMaxText}>
        {displayWeightLbs} lbs&nbsp;
        {exercise.weightMultiplier > 1 && `x${exercise.weightMultiplier} `}
        {exercise.baseWeightLbs > 0 && `+ ${exercise.baseWeightLbs} lbs `}={' '}
        {weightLbs} lbs
      </div>
    );
  }

  _renderWarmUp() {
    const { exercise, exerciseSet } = this.props;
    const { weightLbs, repCount } = this.state;
    // const predictedORM = exerciseSet && calculateOneRepMax(exerciseSet)
    /*
     * Find the ORM for 6 reps
     * 125 ->
     *   1) 50% of 125, so 62.5
     * */

    const orm = calculateOneRepMax({ weightLbs, repCount });
    const workingSetPercentOfORM = 0.85;
    const workingSetWeight = Math.round(orm * workingSetPercentOfORM);
    const warmUpSets = [
      {
        repCount: 12,
        weightLbs: Math.round(orm * (workingSetPercentOfORM * 0.5)),
      },
      {
        repCount: 10,
        descriptor: 'fast',
        weightLbs: Math.round(orm * (workingSetPercentOfORM * 0.5)),
      },
      {
        repCount: 4,
        weightLbs: Math.round(orm * (workingSetPercentOfORM * 0.7)),
      },
      {
        repCount: 1,
        weightLbs: Math.round(orm * (workingSetPercentOfORM * 0.9)),
      },
    ];

    return (
      <div className={styles.warmUpModalView}>
        <div className={styles.oneRepMaxTextBold}>Your ORM: {orm} lbs</div>
        <div className={styles.oneRepMaxTextBold}>
          {`Working weight: ${workingSetWeight} lbs\n`}
        </div>

        <div className={styles.oneRepMaxTextBold}>Warm up sets</div>
        <div>
          {warmUpSets.map(({ repCount, weightLbs, descriptor }, i) => (
            <div key={i}>
              <div className={styles.warmUpText}>
                {i + 1}) {repCount} rep{repCount === 1 ? '' : 's'} of{' '}
                {weightLbs} lbs {descriptor ? descriptor : ''}
              </div>
              {(exercise.baseWeightLbs !== 0 ||
                exercise.weightMultiplier !== 1) &&
                this._renderWeightBreakdownText(weightLbs)}
            </div>
          ))}
        </div>
        <div className={styles.warmUpModalCloseButtonView}>
          <FontAwesomeIcon
            icon={faFire}
            size="2x"
            onClick={() => this.setState({ showingWarmUpModal: false })}
          />
        </div>
      </div>
    );
  }

  _renderTimer() {
    let timerStr = 'Log a set!';
    const { mostRecentExerciseSetAny } = this.props;
    if (mostRecentExerciseSetAny) {
      const secondsSince = Sugar.Date.secondsSince(
        new Date(),
        mostRecentExerciseSetAny.createdAt,
      );
      const minutes = Math.floor(secondsSince / 60);
      timerStr = `${minutes}:${padStart(
        secondsSince % 60,
        2,
        '0',
      )} since last set`;
    }
    const percent = this._getPercentRestComplete(this.props);

    const { clientWidth } = document.body;
    const totalWidth = clientWidth - 4 * 2;
    const timerBarWidth = totalWidth * percent;
    const percentRestComplete = this._getPercentRestComplete(this.props);
    const backgroundColor = chroma
      .scale('Spectral')
      .domain([1, 0])(percentRestComplete)
      .hex();
    return (
      <div className={styles.timerSection}>
        <div
          className={styles.timerBar}
          style={{
            width: timerBarWidth,
            backgroundColor: backgroundColor,
          }}
        />
        <div className={styles.timerText}>{timerStr}</div>
      </div>
    );
  }

  _renderBody() {
    const repsUnitLabel = 'rep';
    const { setsPast, exercise } = this.props;
    const {
      repCount,
      weightLbs,
      repOptions,
      inputFocused,
      rawInputTexts,
      showingNewRecord,
      users,
      showingWarmUpModal,
    } = this.state;

    const pickerTextInputProps = {
      style: styles.pickerTextInput,
      onFocus: () => this._setInputFocused(true),
      onBlur: () => {
        this._blurTimer = setTimeout(() => {
          this._setInputFocused(false);
        }, 250);
      },
    };

    const exerciseSetsPast = setsPast
      .map((set) => {
        const userIndex = users.findIndex(
          (user) => user.id === set.doneByUserId,
        );
        const color = ExerciseDetailScreen.userColors[userIndex];
        return { ...set, color };
      })
      .reverse();
    const orm = `${calculateOneRepMax({ weightLbs, repCount })}`;
    const exerciseSet = setsPast[setsPast.length - 1];

    return (
      <div className={styles.root}>
        <div className={styles.logSection}>
          <div className={styles.logsTitleRow}>
            <div className={styles.logsTitleText}>Logs</div>
            {exerciseSet && (
              <button
                className={styles.undoText}
                onClick={() => this._undoLastSet()}
              >
                undo
              </button>
            )}
          </div>
          <ExerciseLogListComponent
            exerciseSets={exerciseSetsPast}
            ref={(el) => (this._logList = el)}
          />
        </div>
        {this._renderTimer()}
        <div className={styles.recordNewSetSection}>
          <div className={styles.statsAndInputsRow}>
            <input
              type="text"
              pattern="[0-9]*"
              {...{
                ...pickerTextInputProps,
                value: rawInputTexts.repCount || '',
                placeholder: 'Reps',
                onChange: (ev) =>
                  this._onChangeRawInputText('repCount', ev.target.value),
              }}
            />
            <input
              type="text"
              pattern="[0-9]*"
              {...{
                ...pickerTextInputProps,
                value: rawInputTexts.weightLbs || '',
                placeholder: 'Weight (lbs)',
                onChange: (ev) =>
                  this._onChangeRawInputText('weightLbs', ev.target.value),
              }}
            />
          </div>
          <div className={styles.recordNewPickers}>
            <div className={styles.pickerColumn}>
              <Picker
                className={styles.recordNewPicker}
                selectedValue={repCount}
                itemStyle={styles.recordNewPickerItem}
                onValueChange={(repCount) => this._onChangeRepPicker(repCount)}
              >
                {repOptions.map((value) => (
                  <PickerItem
                    label={`${value} ${repsUnitLabel}${value !== 1 ? 's' : ''}`}
                    value={value}
                    key={'rep-' + value}
                  />
                ))}
              </Picker>
            </div>
            <div className={styles.pickerColumn}>
              <WeightPicker
                value={this.state.weightLbs}
                minWeightLbs={exercise.baseWeightLbs}
                className={styles.recordNewPicker}
                itemStyle={styles.recordNewPickerItem}
                onValueChange={(weightLbs) =>
                  this._onChangeWeightPicker(weightLbs)
                }
              />
            </div>
          </div>
          <div className={styles.recordNewButtonView}>
            {this._renderWeightBreakdownBar()}
            <div
              className={styles.oneRepMaxRow}
              onClick={() => this._showWarmUpModal()}
            >
              One rep max of&nbsp;
              <span className={styles.oneRepMaxTextBold}>{orm}</span>
              &nbsp;lbs
            </div>
          </div>
        </div>
        <div className={styles.keyboardButtonView}>
          {false && inputFocused && (
            <div className={styles.keyboardButtonRow}>
              <button title="Done" onClick={() => this._doneEditing()} />
            </div>
          )}
          {
            <button
              style={{ backgroundColor: this._getSelectedUserColor() }}
              className={styles.recordNewButton}
              onClick={() => this._recordNewSet()}
            >
              Log
            </button>
          }
        </div>
        {showingWarmUpModal && this._renderWarmUp()}
        {showingNewRecord && (
          <NewRecordOverlay
            onDismiss={() => this.setState({ showingNewRecord: false })}
          />
        )}
      </div>
    );
  }

  render() {
    const { exercise, user } = this.props;
    if (!exercise || !user) return null;
    const title = this.props.exercise.name;
    return (
      <ScreenLayoutComponent
        title={title}
        body={this._renderBody()}
        leftNode={<ScreenTitleBackButton />}
        rightNode={
          <ScreenTitleEditButton
            onClick={() =>
              this.props.pushScreen({
                id: 'exerciseUpdate',
                exerciseId: exercise.id,
              })
            }
          />
        }
      />
    );
  }
}

// Helpers

function calculateOneRepMax(exerciseSet) {
  const { weightLbs, repCount } = exerciseSet;
  if (repCount === 1) return weightLbs;
  try {
    return Math.round(oneRepMax.epley({ weight: weightLbs, reps: repCount }));
  } catch (e) {
    return weightLbs;
  }
}

function calcPlateWeight(weightLbs, exercise) {
  return (weightLbs - exercise.baseWeightLbs) / exercise.weightMultiplier;
}

//

const mapStateToProps = (state, props) => {
  const exercise = selectedExerciseSelector(state);
  const { exerciseSets = [] } = state.exercise;
  const setsForSelectedExercise =
    (exercise && filter(exerciseSets || [], { exerciseId: exercise.id })) || [];
  const setsToday = setsForSelectedExercise;
  const setsPast = setsForSelectedExercise;

  const aDayAgo = Sugar.Date.rewind(new Date(), '24 hours');
  const setsWithinLastDay = (exerciseSets || []).filter((set) =>
    Sugar.Date.isAfter(new Date(set.createdAt), aDayAgo),
  );
  const mostRecentExerciseSetAny =
    setsWithinLastDay[setsWithinLastDay.length - 1];

  const { user } = state.login;
  const exerciseSet =
    user &&
    setsPast
      .slice()
      .reverse()
      .find((set) => set.doneByUserId === user.id);

  return {
    setsPast,
    setsToday,
    exerciseSet,
    exercise,
    mostRecentExerciseSetAny,
    user: state.login.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popScreen: () => dispatch(NavigationActions.popScreen()),
    pushScreen: (screen) => dispatch(NavigationActions.pushScreen(screen)),
    createExerciseSet: (exerciseSet) =>
      dispatch(ExerciseActions.createExerciseSet(exerciseSet)),
    destroyExerciseSet: (exerciseSetId) =>
      dispatch(ExerciseActions.destroyExerciseSet(exerciseSetId)),
    loadExerciseSets: (exerciseSets) =>
      dispatch(ExerciseActions.loadExerciseSets(exerciseSets)),
    setSelectedExerciseId: (exerciseId) =>
      dispatch(ExerciseActions.setSelectedExerciseId(exerciseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExerciseDetailScreen);
