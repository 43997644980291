import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import localforage from "localforage"; // defaults to localStorage for web and AsyncStorage for react-native
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'

// creates the store
export default (rootReducer) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = []
  const enhancers = []

  /* ------------- Saga Middleware ------------- */

  // const sagaMonitor = __DEV__ ? console.tron.createSagaMonitor() : null
  // const sagaMiddleware = createSagaMiddleware({ sagaMonitor })
  middleware.push(thunk)

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware))

  /* ------------- AutoRehydrate Enhancer ------------- */

  // add the autoRehydrate enhancer
  // if (ReduxPersist.active) {
  //   enhancers.push(autoRehydrate())
  // }

  const persistedReducer = persistReducer({
    key: 'root',
    storage: localforage,
    transforms: [seamlessImmutableTransformCreator({
      whitelistPerReducer: {
        // reducerA: ['keyA', 'keyB']
      },
      blacklistPerReducer: {
        // reducerB: ['keyC', 'keyD']
      }
    })]
  }, rootReducer)

  const store = createStore(persistedReducer, compose(...enhancers))
  const persistor = persistStore(store)

  // configure persistStore and check reducer version number
  // if (ReduxPersist.active) {
  //   RehydrationServices.updateReducers(store)
  // }

  // kick off root saga
  // sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
