import React, { Component } from 'react';
import styles from './WorkoutsScreenComponent.module.scss';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import ItemListComponent from '../../components/ItemListComponent/ItemListComponent';
import FirebaseService from '../../Services/FirebaseService';
import connect from 'react-redux/es/connect/connect';
import ExerciseActions from '../../Redux/ExerciseStore';
import NavigationActions from '../../Redux/NavigationRedux';

class WorkoutsScreenComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tick: 0,
    };
  }

  _selectWorkout(workout) {
    console.log(`_selectWorkout`,);
    const workoutId = workout.id;
    this.props.pushScreen({ id: 'workoutDetail', workoutId })
  }

  _startDeleteWorkout(workout, closeRow) {
    // Alert.alert(
    //   `Are you sure you want to delete "${workout.name}"?`,
    //   'This workout will be deleted forever. This cannot be undone!',
    //   [
    //     {text: 'Cancel', onClick: () => closeRow(), style: 'cancel'},
    //     {text: 'Delete', onClick: () => this._deleteWorkout(workout, closeRow)},
    //   ],
    //   { cancelable: true }
    // )
  }

  _deleteWorkout(workout) {
    FirebaseService.create().destroyWorkout(workout);
    this.props.destroyWorkout(workout);
  }

  _renderWorkoutRow(workout) {
    const { name, exerciseIds } = workout;

    return (
      <div
        className={styles.workoutRow}
        onClick={() => this._selectWorkout(workout)}
      >
        <div className={styles.workoutTitle}>{name}</div>
        <div className={styles.workoutExerciseCount}>
          {(exerciseIds || []).length} exercises
        </div>
      </div>
    );
  }

  _renderHiddenRow(workout, secId, rowId, rowMap) {
    const closeRow = () => rowMap[`${secId}${rowId}`].closeRow();
    return (
      <div
        className={styles.rowSwipeMenu}
        onClick={this._startDeleteWorkout.bind(this, workout, closeRow)}
      >
        <div className={styles.rowSwipeMenuText}>Delete</div>
      </div>
    );
  }

  _renderBody() {
    const { workouts } = this.props;
    console.log(`workouts`, workouts);
    return (
      <div className={styles.body}>
        <ItemListComponent
          items={workouts}
          renderRow={this._renderWorkoutRow.bind(this)}
          renderHiddenRow={this._renderHiddenRow.bind(this)}
          rightOpenValue={-75}
          disableRightSwipe={true}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles.root}>
        <ScreenLayoutComponent title={'Workouts'} body={this._renderBody()} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.login;
  const { workouts, activeWorkoutId } = state.exercise;
  return { user, workouts, activeWorkoutId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endWorkout: (workout) => dispatch(ExerciseActions.endWorkout()),
    createWorkout: (workout) =>
      dispatch(ExerciseActions.createWorkout(workout)),
    destroyWorkout: (workout) =>
      dispatch(ExerciseActions.destroyWorkout(workout)),
    setActiveWorkoutId: (workoutId) =>
      dispatch(ExerciseActions.setActiveWorkoutId(workoutId)),
    pushScreen: (screen) => dispatch(NavigationActions.pushScreen(screen)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkoutsScreenComponent);
