import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { filter, startsWith, groupBy, sortBy, zip, without } from 'lodash';
import { createSelector } from 'reselect';
import Fuse from 'fuse.js';
import FirebaseService from '../Services/FirebaseService';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  search: ['searchTerm'],
  cancelSearch: null,
  forceUpdateExerciseStore: ['newState'],
  createExercise: ['exercise'],
  updateExercise: ['exercise'],
  destroyExercise: ['exercise'],
  setSelectedExerciseId: ['exerciseId'],
  createExerciseSet: ['exerciseSet'],
  destroyExerciseSet: ['exerciseSetId'],
  loadExerciseSets: ['exerciseSets'],
  createWorkout: ['workout'],
  updateWorkout: ['workout'],
  setActiveWorkoutId: ['workoutId'],
  destroyWorkout: ['workout'],
  endWorkout: null,
});

export const { search } = Creators;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  searchTerm: '',
  searching: false,
  results: [],
  exercises: [],
  exerciseSets: [],
  workouts: [],
  selectedExercise: null,
  selectedExerciseId: null,
  activeWorkoutId: null,
});

/* ------------- Reducers ------------- */

const updateSearchResults = (state) => {
  const { exercises, searchTerm, exerciseSets } = state;
  const options = {
    shouldSort: true,
    tokenize: true,
    includeMatches: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: ['name'],
  };
  let results;
  let sortByLastSet = false;
  if (searchTerm) {
    const fuse = new Fuse(exercises, options);
    results =
      fuse.search(searchTerm).map((result) => ({ exercise: result.item })) ||
      [];
  } else {
    sortByLastSet = true;
    results = state.exercises.map((exercise) => ({ exercise })) || [];
  }
  const exerciseSetsByExerciseId = groupBy(exerciseSets, 'exerciseId');

  // Add the sets sorted by last performed date
  results = results.map((result) => {
    const sets = sortBy(
      exerciseSetsByExerciseId[result.exercise.id] || [],
      'createdAt',
    );
    return {
      ...result,
      sets,
    };
  });

  if (sortByLastSet) {
    results = sortBy(results, (result) => {
      const sets = result.sets;
      const lastSet = sets[sets.length - 1];
      return (lastSet && lastSet.createdAt) || -Infinity;
    }).reverse();
  }

  return state.merge({ results });
};

const performSearchReducer = (state, { searchTerm }) => {
  state = state.merge({ searchTerm: searchTerm.toLowerCase() });
  return updateSearchResults(state);
};

export const cancelSearch = (state) => INITIAL_STATE;

export const forceUpdateExerciseStore = (state, action) => {
  const { newState } = action;
  state = state.merge(newState);
  return updateSearchResults(state);
};

function updateCollectionItem({ collection, item }) {
  const existingRecord = collection.find(({ id }) => id === item.id);
  if (!existingRecord) return collection;

  const updatedRecord = {
    ...existingRecord,
    ...item,
  };
  const updatedCollection = [
    ...without(collection, existingRecord),
    updatedRecord,
  ];
  return updatedCollection;
}

// Exercise ---

export const createExercise = (state, { exercise }) => {
  state = state.merge({
    exercises: [...state.exercises, exercise],
  });
  return updateSearchResults(state);
};
export const updateExercise = (state, { exercise }) => {
  const exercises = updateCollectionItem({
    collection: state.exercises,
    item: exercise,
  });
  state = state.merge({ exercises });
  return updateSearchResults(state);
};

export const destroyExercise = (state, { exercise }) => {
  state = state.merge({
    exercises: state.exercises.filter(({ id }) => exercise.id !== id),
  });
  return updateSearchResults(state);
};

export const setSelectedExerciseId = (state, { exerciseId }) => {
  return state.merge({ selectedExerciseId: exerciseId });
};

// ExerciseSet ---

export const createExerciseSet = (state, { exerciseSet }) => {
  state = state.merge({
    exerciseSets: [...(state.exerciseSets || []), exerciseSet],
  });
  return updateSearchResults(state);
};
export const destroyExerciseSet = (state, { exerciseSetId }) => {
  state = state.merge({
    exerciseSets: state.exerciseSets.filter(
      (_set) => _set.id !== exerciseSetId,
    ),
  });
  return updateSearchResults(state);
};

// Workouts ---

export const createWorkout = (state, { workout }) => {
  return state.merge({
    workouts: [...(state.workouts || []), workout],
    activeWorkoutId: workout.id,
  });
};
export const updateWorkout = (state, { workout }) => {
  const workouts = updateCollectionItem({
    collection: state.workouts,
    item: workout,
  });
  return state.merge({ workouts });
};
export const setActiveWorkoutId = (state, { workoutId }) => {
  return state.merge({
    activeWorkoutId: workoutId,
  });
};
export const endWorkout = (state) => {
  return state.merge({
    activeWorkout: null,
  });
};

export const destroyWorkout = (state, { workout }) => {
  state = state.merge({
    workouts: state.workouts.filter(({ id }) => workout.id !== id),
  });
  return state;
};

export const loadExerciseSets = (state, { exerciseSets }) => {
  // Merge in new sets
  // const existingExerciseSetids = new Set(state.exerciseSets.map(({id}) => id))
  // const updatedExerciseSets = [...state.exerciseSets]
  // exerciseSets.forEach(exerciseSet => {
  //   if (!existingExerciseSetids.has(exerciseSet.id)) {
  //     updatedExerciseSets.push(exerciseSet)
  //   }
  // })

  // Replace sets
  const updatedExerciseSets = exerciseSets;
  return state.merge({
    exerciseSets: updatedExerciseSets,
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SEARCH]: performSearchReducer,
  [Types.CANCEL_SEARCH]: cancelSearch,
  [Types.CREATE_EXERCISE]: createExercise,
  [Types.UPDATE_EXERCISE]: updateExercise,
  [Types.DESTROY_EXERCISE]: destroyExercise,
  [Types.FORCE_UPDATE_EXERCISE_STORE]: forceUpdateExerciseStore,
  [Types.SET_SELECTED_EXERCISE_ID]: setSelectedExerciseId,
  [Types.CREATE_EXERCISE_SET]: createExerciseSet,
  [Types.DESTROY_EXERCISE_SET]: destroyExerciseSet,
  [Types.LOAD_EXERCISE_SETS]: loadExerciseSets,
  [Types.CREATE_WORKOUT]: createWorkout,
  [Types.UPDATE_WORKOUT]: updateWorkout,
  [Types.SET_ACTIVE_WORKOUT_ID]: setActiveWorkoutId,
  [Types.DESTROY_WORKOUT]: destroyWorkout,
  [Types.END_WORKOUT]: endWorkout,
});
