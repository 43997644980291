import React, { Component } from 'react';
import LoginActions from '../../Redux/LoginRedux';
import styles from './SettingsScreenComponent.module.scss';
import connect from 'react-redux/es/connect/connect';
import UIStateActions from '../../Redux/UIStateRedux';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import NavigationActions from '../../Redux/NavigationRedux';
import packageJson from '../../../package.json';

class SettingsScreenComponent extends Component {
  _forceRefresh = () => {
    // Wipe the service-worker cache
    caches.keys().then((keys) => {
      keys.forEach((key) => {
        caches.delete(key);
      });
    });
    this.props.setActivePageId('home');
    window.location.reload(true);
  };

  _renderBody() {
    const { user, logout } = this.props;
    if (!user) return null;

    return (
      <div className={styles.root}>
        <button
          onClick={() =>
            this.props.pushScreen({
              id: 'csvExport',
            })
          }
        >
          Export CSV
        </button>
        <button onClick={this._forceRefresh}>Force Refresh</button>
        <button onClick={logout}>Logout</button>
        <div className={styles.version}>
          v{packageJson.version}
        </div>
      </div>
    );
  }

  render() {
    return (
      <ScreenLayoutComponent title={'Settings'} body={this._renderBody()} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
  };
};

export default connect(
  mapStateToProps,
  (dispatch) => ({
    logout: () => {
      dispatch(LoginActions.logout());
      dispatch(UIStateActions.setActivePageId('home'));
    },
    pushScreen: (screen) => dispatch(NavigationActions.pushScreen(screen)),
    setActivePageId: (pageId) =>
      dispatch(UIStateActions.setActivePageId(pageId)),
  }),
)(SettingsScreenComponent);
