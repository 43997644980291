import React from 'react';
import { connect } from 'react-redux';
import ExerciseActions from '../../Redux/ExerciseStore';
import FirebaseService from '../../Services/FirebaseService';
import styles from './ExerciseUpdateScreen.module.scss';
import NavigationActions from '../../Redux/NavigationRedux';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import selectedExerciseSelector from '../../Selectors/selectedExerciseSelector';
import FormLabel from '../../components/Forms/FormLabel';
import FormInput from '../../components/Forms/FormInput';
import ScreenTitleBackButton from '../../components/ScreenTitleButtons/ScreenTitleBackButton';

class ExerciseUpdateScreen extends React.Component {
  constructor(props) {
    super(props);
    const defaultExercise = {
      name: 'New Exercise',
      baseWeightLbs: 0,
      weightMultiplier: 1,
      createdByUserId: props.user.id,
    };
    const exercise = props.exercise || defaultExercise;
    this.state = {
      exercise: exercise,
      ...this._getReadableExercise(exercise),
    };
  }

  _getReadableExercise(exercise) {
    const { baseWeightLbs, weightMultiplier } = exercise;
    return {
      baseWeightLbsStr: `${baseWeightLbs} lbs`,
      weightMultiplierStr: `${weightMultiplier}`,
    };
  }

  componentWillMount() {
    const title = this.state.exercise.id
      ? 'Edit Exercise'
      : 'Create New Exercise';
    // NavigationActions.refresh({ title })
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      exercise: {
        ...this.state.exercise,
        createdByUserId: newProps.user.id,
      },
    });
  }

  _updateExerciseProp(prop, value) {
    const exercise = {
      ...this.state.exercise,
      [prop]: value,
    };
    this.setState({ exercise, ...this._getReadableExercise(exercise) });
  }

  _updateExercisePropNumeric(prop, value, defaultValue) {
    const exercise = {
      ...this.state.exercise,
      [prop]: parseInt(value, 10) || defaultValue,
    };
    this.setState({ exercise, ...this._getReadableExercise(exercise) });
  }

  _updateExercise() {
    const { exercise } = this.state;
    FirebaseService.create()
      .updateExercise(exercise)
      .then((newExercise) => {
        if (exercise.id) {
          this.props.updateExercise(newExercise);
          this.props.popScreen();
        } else {
          this.props.createExercise(newExercise);
          this.props.replaceScreen({
            id: 'exerciseDetail',
            exerciseId: newExercise.id,
          });
        }
        // this.props.setSelectedExerciseId(newExercise.id)
        // NavigationActions.exerciseDetail()
      });
  }

  _renderBody() {
    const { exercise, baseWeightLbsStr, weightMultiplierStr } = this.state;
    const { id, name } = exercise;
    return (
      <div className={styles.root}>
        <FormLabel>Exercise name</FormLabel>
        <FormInput
          value={name}
          onChange={(ev) => this._updateExerciseProp('name', ev.target.value)}
        />
        <FormLabel>Base weight (lbs)</FormLabel>
        <FormInput
          value={baseWeightLbsStr}
          onChange={(ev) =>
            this.setState({ baseWeightLbsStr: ev.target.value })
          }
          onBlur={(ev) =>
            this._updateExercisePropNumeric('baseWeightLbs', ev.target.value, 0)
          }
        />
        <FormLabel>Weight multiplier</FormLabel>
        <FormInput
          value={weightMultiplierStr}
          onChange={(ev) =>
            this.setState({ weightMultiplierStr: ev.target.value })
          }
          onBlur={(ev) =>
            this._updateExercisePropNumeric(
              'weightMultiplier',
              ev.target.value,
              1,
            )
          }
        />
        <div className={styles.buttonRow}>
          <button onClick={() => this._updateExercise()}>
            {id ? 'Save' : 'Create'}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { exercise } = this.state;
    const { user } = this.props;
    if (!exercise || !user) return null;
    return (
      <ScreenLayoutComponent
        title={exercise.name}
        body={this._renderBody()}
        leftNode={<ScreenTitleBackButton />}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    exercise: selectedExerciseSelector(state),
    user: state.login.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popScreen: () => dispatch(NavigationActions.popScreen()),
    replaceScreen: (screen) =>
      dispatch(NavigationActions.replaceScreen(screen)),
    createExercise: (exercise) =>
      dispatch(ExerciseActions.createExercise(exercise)),
    updateExercise: (exercise) =>
      dispatch(ExerciseActions.updateExercise(exercise)),
    setSelectedExerciseId: (exerciseId) =>
      dispatch(ExerciseActions.setSelectedExerciseId(exerciseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExerciseUpdateScreen);
