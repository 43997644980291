import React, { Component } from 'react';
import styles from './ItemListComponent.module.scss';

class ItemListComponent extends Component {
  _renderItemRow(item) {
    const { renderRow, renderHiddenRow } = this.props;
    return (
      <div className={styles.row}>
        {false && (
          <div className={styles.hidden}>
            {renderHiddenRow(item)}
          </div>
        )}
        <div className={styles.main}>
          {renderRow(item)}
        </div>
      </div>
    );
  }

  render() {
    const { items } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.scrollBox} data-scrollable>
          {items.map((item) => this._renderItemRow(item))}
        </div>
      </div>
    );
  }
}

export default ItemListComponent;
