import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { createSelector } from "reselect";
import last from 'lodash/last';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  popScreen: [],
  setScreen: ['screen'],
  pushScreen: ['screen'],
  replaceScreen: ['screen'],
})

export const NavigationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  screenStack: [],
})

/* ------------- Reducers ------------- */

const setScreen = (state, { screen }) => {
  return state.merge({ screenStack: [screen] })
}

const pushScreen = (state, { screen }) => {
  return state.merge({ screenStack: [...state.screenStack, screen] })
}

const popScreen = (state) => {
  const screenStack = state.screenStack.slice(0, -1);
  return state.merge({ screenStack })
}

const replaceScreen = (state, { screen }) => {
  const screenStack = [
    ...state.screenStack.slice(0, -1),
    screen,
  ];
  return state.merge({ screenStack })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PUSH_SCREEN]: pushScreen,
  [Types.POP_SCREEN]: popScreen,
  [Types.SET_SCREEN]: setScreen,
  [Types.REPLACE_SCREEN]: replaceScreen,
})

/* ------------- Selectors ------------- */

export const activeScreenSelector = createSelector(
  [
    ({ navigation }) => navigation
  ],
  ({ screenStack }) => last(screenStack)
);
