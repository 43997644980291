import React, { Component } from 'react';
import styles from './LiftLogComponent.module.scss';
import DrawerComponent from '../../DrawerComponent/DrawerComponent';
import HomeScreenComponent from '../../screens/HomeScreenComponent/HomeScreenComponent';
import { connect } from 'react-redux';
import UIStateActions from '../../Redux/UIStateRedux';
import WorkoutsScreenComponent from '../../screens/WorkoutsScreenComponent/WorkoutsScreenComponent';
import ExercisesScreenComponent from '../../screens/ExercisesScreenComponent/ExercisesScreenComponent';
import SettingsScreenComponent from '../../screens/SettingsScreenComponent/SettingsScreenComponent';
import ExerciseDetailScreen from '../../screens/ExerciseDetailScreen/ExerciseDetailScreen';
import { activeScreenSelector } from '../../Redux/NavigationRedux';
import ExerciseUpdateScreen from '../../screens/ExerciseUpdateScreen/ExerciseUpdateScreen';
import ExportCSVScreen from '../../screens/ExportCSVScreen/ExportCSVScreen';
import WorkoutDetailScreen from "../../screens/WorkoutDetailScreen/WorkoutDetailScreen";
import WorkoutUpdateScreen from "../../screens/WorkoutUpdateScreen/WorkoutUpdateScreen";

const fullScreenComponentsByScreenId = {
  exerciseDetail: ExerciseDetailScreen,
  exerciseUpdate: ExerciseUpdateScreen,
  csvExport: ExportCSVScreen,
  workoutDetail: WorkoutDetailScreen,
  workoutUpdate: WorkoutUpdateScreen,
};

const screenComponentsByScreenId = {
  home: HomeScreenComponent,
  settings: SettingsScreenComponent,
  exercises: ExercisesScreenComponent,
  workouts: WorkoutsScreenComponent,
};

class LiftLogComponent extends Component {
  componentDidMount() {
    const { user, activePageId, setActivePageId } = this.props;
    if (!user && activePageId !== 'home') setActivePageId('home');
  }

  render() {
    const { user, activePageId, activeScreen } = this.props;
    const pageComponent = screenComponentsByScreenId[activePageId];
    const screenComponent =
      activeScreen && fullScreenComponentsByScreenId[activeScreen.id];
    return (
      <div className={styles.root}>
        <div className={styles.screen}>
          {React.createElement(pageComponent)}
        </div>
        {user && (
          <div className={styles.drawer}>
            <DrawerComponent />
          </div>
        )}
        <div className={styles.fullScreen}>
          {screenComponent &&
            React.createElement(screenComponent, activeScreen)}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { selectedExerciseId } = state.exercise;
    const activeScreen = activeScreenSelector(state);
    return {
      activeScreen,
      selectedExerciseId,
      user: state.login.user,
      activePageId: state.uistate.activePageId,
    };
  },
  (dispatch) => ({
    setActivePageId: (pageId) =>
      dispatch(UIStateActions.setActivePageId(pageId)),
  }),
)(LiftLogComponent);
