import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import styles from './ScreenTitleBackButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import NavigationActions from '../../Redux/NavigationRedux';

class ScreenTitleBackButton extends Component {
  render() {
    return (
      <button
        className={styles.backButton}
        onClick={() => this.props.popScreen()}
      >
        <FontAwesomeIcon icon={faChevronLeft} size="1x" />
      </button>
    );
  }
}

export class ScreenTitleEditButton extends Component {
  render() {
    const { onClick } = this.props;
    return (
      <button
        className={styles.backButton}
        onClick={onClick || (() => this.props.popScreen())}
      >
        <FontAwesomeIcon icon={faPencilAlt} size="1x" />
      </button>
    );
  }
}

export class ScreenTitleNewButton extends Component {
  render() {
    const { onClick } = this.props;
    return (
      <button
        className={styles.backButton}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faPlus} size="1x" />
      </button>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    popScreen: () => dispatch(NavigationActions.popScreen()),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ScreenTitleBackButton);
