import React, { Component } from 'react';
import styles from './ExercisesScreenComponent.module.scss';
import ScreenLayoutComponent from '../../components/ScreenLayoutComponent/ScreenLayoutComponent';
import ItemListComponent from '../../components/ItemListComponent/ItemListComponent';
import connect from 'react-redux/es/connect/connect';
import ExerciseActions, { search } from '../../Redux/ExerciseStore';
import NavigationActions from '../../Redux/NavigationRedux';
import Sugar from 'sugar-date';
import FirebaseService from '../../Services/FirebaseService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ScreenTitleNewButton } from '../../components/ScreenTitleButtons/ScreenTitleBackButton';
import FormInput from '../../components/Forms/FormInput';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// import FloatingActionButtonComponent from "../../Components/FloatingActionButtonComponent/FloatingActionButtonComponent";

class ExercisesScreenComponent extends Component {
  _clearSearch() {
    // TextInputState.focusTextInput(findNodeHandle(this._searchInput))
    this.props.setSearchTerm('');
  }

  _updateSearchTerm(text) {
    this.props.setSearchTerm(text);
    // this._scrollView.scrollTo({y: 0})
  }

  _selectExercise(exercise) {
    this.props.pushScreen({ id: 'exerciseDetail', exerciseId: exercise.id });
  }

  _syncExercises() {
    this.props.syncExercises();
    // yield new Promise(resolve => setTimeout(resolve, 250))
  }

  _deleteExercise(exercise) {
    FirebaseService.create().destroyExercise(exercise);
    this.props.destroyExercise(exercise);
  }

  _renderNewItemRow() {
    /*<div className={styles.exerciseRow} onClick={NavigationActions.exerciseNew}>*/
    return (
      <div className={styles.exerciseRow} onClick={() => {}}>
        <div className={styles.exerciseTitle}>Create new exercise</div>
      </div>
    );
  }

  _renderResultRow = (rowData) => {
    const { exercise } = rowData;
    const sets = rowData.sets || [];
    const mostRecentSet = sets[sets.length - 1];
    let opacity = 0;
    const hourAgoEpoch = Sugar.Number.hoursAgo(1).getTime();

    if (mostRecentSet) {
      const recentSetEpoch = new Date(mostRecentSet.createdAt).getTime();
      opacity =
        Math.max(0, recentSetEpoch - hourAgoEpoch) / (1 * 60 * 60 * 1000); // 1 hour
    }
    // const lastSetText = `${new Sugar.Date(mostRecentSet.createdAt).relative()}`;
    return (
      <div onClick={() => this._selectExercise(exercise)}>
        <div className={styles.exerciseRow}>
          <div className={styles.exerciseTitle}>
            <div className={styles.text}>{exercise.name}</div>
          </div>
          <div className={styles.star} style={{ opacity }}>
            <FontAwesomeIcon
              size={'xs'}
              icon={faStar}
              className={styles.exerciseRowTodayIcon}
            />
          </div>
          {sets.length > 0 && (
            <div className={styles.exerciseRecentSets}>
              {sets.length > 0 && `${sets.length} sets`}
            </div>
          )}
        </div>
      </div>
    );
  };

  _startDeleteExercise = (exercise, closeRow) => {
    // Alert.alert(
    //   `Are you sure you want to delete "${exercise.name}"?`,
    //   'This exercise and all recorded sets will be deleted forever. This cannot be undone!',
    //   [
    //     { text: 'Cancel', onClick: () => closeRow(), style: 'cancel' },
    //     { text: 'Delete', onClick: () => this._deleteExercise(exercise) },
    //   ],
    //   { cancelable: true }
    // )
  };

  _navToCreateExercise() {
    this.props.setSelectedExerciseId(null);
    // NavigationActions.exerciseNew()
  }

  _renderHiddenRow = ({ exercise }, secId, rowId, rowMap) => {
    const closeRow = () => rowMap[`${secId}${rowId}`].closeRow();
    return (
      <div
        className={styles.rowSwipeMenu}
        onClick={this._startDeleteExercise.bind(this, exercise, closeRow)}
      >
        <div className={styles.rowSwipeMenuText}>Delete</div>
      </div>
    );
  };

  _renderSearchBar() {
    const { searchTerm } = this.props;
    return (
      <div className={styles.searchBar}>
        <FontAwesomeIcon
          size={'xs'}
          icon={faSearch}
          className={styles.searchIcon}
        />
        <div className={styles.searchInputWrapper}>
          <FormInput
            placeholder="Search"
            value={searchTerm}
            className={styles.searchInput}
            onChange={(searchTerm) => this.props.search(searchTerm)}
          />
          <FontAwesomeIcon
            size={'xs'}
            icon={faTimesCircle}
            className={styles.searchClear}
            onClick={() => this.props.search('')}
          />
        </div>
      </div>
    );
  }

  _renderBody() {
    const { results } = this.props;
    return (
      <div className={styles.body}>
        <ItemListComponent
          items={results}
          renderRow={this._renderResultRow}
          renderHiddenRow={this._renderHiddenRow}
        />
        {this._renderSearchBar()}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.root}>
        <ScreenLayoutComponent
          title={'Exercises'}
          body={this._renderBody()}
          rightNode={
            <ScreenTitleNewButton
              onClick={() =>
                this.props.pushScreen({
                  id: 'exerciseUpdate',
                })
              }
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.login;
  const { searchTerm, results, activeWorkout, workouts } = state.exercise;

  const activeWorkoutProfiles = new Array(1).fill(user.profile);

  return {
    user,
    searchTerm,
    results,
    activeWorkout,
    activeWorkoutProfiles,
    workouts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (searchTerm) => dispatch(search(searchTerm)),
    pushScreen: (screen) => dispatch(NavigationActions.pushScreen(screen)),
    createWorkout: (workout) =>
      dispatch(ExerciseActions.createWorkout(workout)),
    endWorkout: (workout) => dispatch(ExerciseActions.endWorkout()),
    destroyExercise: (exercise) =>
      dispatch(ExerciseActions.destroyExercise(exercise)),
    forceUpdateExerciseStore: (newState) => {
      dispatch(ExerciseActions.forceUpdateExerciseStore(newState));
    },
    setSelectedExerciseId: (exerciseId) =>
      dispatch(ExerciseActions.setSelectedExerciseId(exerciseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExercisesScreenComponent);
