import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import styles from './NewRecordOverlay.module.scss';
import Confetti from 'react-confetti';
import sizeMe from 'react-sizeme'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { Transition } from "react-spring";
import { Spring } from "react-spring";

const NewRecordOverlay = sizeMe({
  monitorHeight: true,
  monitorWidth: true,
})(class NewRecordOverlayInner extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = { revealed: false }
  }

  render() {
    const { size, onDismiss } = this.props;
    return (
      <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
        {props => (
          <div className={styles.root} onClick={onDismiss} style={props}>
            <div className={styles.background}>
              <Confetti width={size.width} height={size.height}/>
            </div>
            <div className={styles.foreground}>
              <Spring
                config={{ delay: 100, tension: 240, friction: 14, precision: 0.1 }}
                from={{ transform: 'scale(0)' }} to={{ transform: 'scale(1)' }}
              >
                {props => (
                  <div className={styles.trophy} style={props}>
                    <FontAwesomeIcon icon={faTrophy} size="8x"/>
                  </div>
                )}
              </Spring>
              <div className={styles.newRecordSection}>
                <div className={styles.newRecordBanner}>
                  <Spring
                    config={{ delay: 250, tension: 220, friction: 18, precision: 0.1 }}
                    from={{ marginLeft: 1000 }} to={{ marginLeft: 0 }}
                  >
                    {props => (
                      <div className={styles.textSlide} style={props}>
                        New record!
                      </div>
                    )}
                  </Spring>
                </div>
              </div>
            </div>
          </div>
        )}
      </Spring>
    );
  }
})

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(NewRecordOverlay)
