import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import styles from './Picker.module.scss';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';
import debounce from 'lodash/debounce';
import { Spring } from "react-spring";

const itemHeight = 30;
const PADDING_HEIGHT = 200;
const PickerContext = React.createContext();

class Picker extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)

    // style={style}
    // selectedValue={value}
    // itemStyle={itemStyle}
    // onValueChange={onValueChange}

    this.state = {
      items: [],
      scrollTop: 0,
      userScrolling: false,
      selectedItemIndex: -1,
      selectedValue: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedValue !== prevState.selectedValue) {
      const selectedItemIndex = prevState.items.findIndex((item) =>
        item.value === nextProps.selectedValue);
      if (prevState.selectedItemIndex !== selectedItemIndex) {
        return { selectedItemIndex, selectedValue: nextProps.selectedValue };
      } else {
        return null;
      }
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedItemIndex !== prevState.selectedItemIndex) {
      const { scrollTop } = this._itemsEl;
      const centeringAdjustment = PADDING_HEIGHT - this._itemsEl.clientHeight / 2 + itemHeight / 2;
      const newScrollTop = this.state.selectedItemIndex * itemHeight + centeringAdjustment;
      this._itemsEl.scrollTop = newScrollTop;
    }
  }

  componentDidMount() {
    this._debouncedScroll = debounce(this._onScrollDebounced, 150);
    this._itemsEl.addEventListener('scroll', this._onScroll);
    this._onScroll();
  }

  componentWillUnmount() {
    this._itemsEl.removeEventListener('scroll', this._onScroll);
  }

  _onScroll = (ev) => {
    const { userScrolling } = this.state;
    const { scrollTop } = this._itemsEl;

    if (userScrolling) {
      this._debouncedScroll.cancel()
    } else {
      this._debouncedScroll(ev);
    }

    const { items } = this.state;
    const centeringAdjustment = PADDING_HEIGHT - this._itemsEl.clientHeight / 2 + itemHeight / 2;
    const adjustedTop = Math.max(0, scrollTop - centeringAdjustment);
    const itemNumber = Math.min(Math.round(adjustedTop / itemHeight), items.length - 1);

    const selectedItem = items[itemNumber];
    if (selectedItem) this.props.onValueChange(selectedItem.value);

    this.setState({
      scrollTop,
      selectedItemIndex: itemNumber,
    })
  }

  _onScrollDebounced = (ev) => {
    if (!this._itemsEl) return;

    const { items, userScrolling } = this.state;
    const { scrollTop } = this._itemsEl;
    const centeringAdjustment = PADDING_HEIGHT - this._itemsEl.clientHeight / 2 + itemHeight / 2;
    const adjustedTop = Math.max(0, scrollTop - centeringAdjustment);
    const itemNumber = Math.min(Math.round(adjustedTop / itemHeight), items.length - 1);
    const newScrollTop = itemNumber * itemHeight + centeringAdjustment;

    this.setState({
      scrollTop: newScrollTop,
      selectedItemIndex: itemNumber,
    })
    if (!userScrolling) {
      this._itemsEl.scrollTop = newScrollTop;
    }
  }

  addItem(item) {
    this.setState((state, props) => {
      const { items } = state;
      const newItems = sortBy([...items, item], 'key');
      return {
        items: newItems,
      };
    })
  }

  removeItem(item) {
    this.setState((state) => ({
      items: without(state.items, [item]),
    }))
  }

  _renderItem(item, itemIndex) {
    const { selectedItemIndex } = this.state;
    const opacity = 1 - Math.abs(itemIndex - selectedItemIndex) * 0.3;
    return (
      <div key={itemIndex} className={styles.itemRow} style={{ opacity }}>
        {item.label}
      </div>
    )
  }

  render() {
    const { children } = this.props;
    const { items } = this.state;
    return (
      <PickerContext.Provider value={this}>
        <div
          className={styles.root}
          onTouchStart={() => this.setState({ userScrolling: true })}
          onTouchEnd={() => {
            this.setState({ userScrolling: false }, () => {
              this._onScroll();
            });
          }}>
          <div
            className={styles.items}
            ref={(el) => this._itemsEl = el}
            data-scrollable
          >
            <div className={styles.padding} style={{ height: PADDING_HEIGHT }}/>
            {items.map((item, i) => this._renderItem(item, i))}
            <div className={styles.padding} style={{ height: PADDING_HEIGHT }}/>
          </div>
          <div className={styles.centerline}></div>
        </div>
        {children}
      </PickerContext.Provider>
    );
  }
}

class PickerItem extends Component {
  static contextType = PickerContext;

  componentDidMount() {
    const picker = this.context;
    const { label, value, key } = this.props;
    this._item = { label, value };
    picker.addItem(this._item);
  }

  componentWillUnmount() {
    const picker = this.context;
    picker.removeItem(this._item);
  }

  render() {
    return null;
  }
}

export default Picker

Picker.Item = PickerItem;
