import { combineReducers } from 'redux'
import configureStore from './CreateStore'

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = combineReducers({
    login: require('./LoginRedux').reducer,
    uistate: require('./UIStateRedux').reducer,
    exercise: require('./ExerciseStore').reducer,
    navigation: require('./NavigationRedux').reducer,
  })

  return configureStore(rootReducer)
}
