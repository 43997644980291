import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './DrawerComponent.module.scss';
import { connect } from 'react-redux';
import UIStateActions from '../Redux/UIStateRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTools, faFire, faDumbbell } from '@fortawesome/free-solid-svg-icons';

class DrawerComponent extends Component {
  static propTypes = {
    name: PropTypes.string,
    activePageId: PropTypes.string,
    setActivePageId: PropTypes.func,
  };

  _navigateToPage(page) {
    console.log(`page`, page.id);
  }

  render() {
    const {
      activePageId,
      setActivePageId,
    } = this.props;

    const items = [
      {
        id: 'home',
        name: 'Home',
        icon: faHome,
      },
      {
        id: 'workouts',
        name: 'Workouts',
        icon: faFire,
      },
      {
        id: 'exercises',
        name: 'Exercises',
        icon: faDumbbell,
      },
      {
        id: 'settings',
        name: 'Settings',
        icon: faTools,
      },
    ];
    return (
      <div className={styles.root}>
        {items.map((item, i) => (
          <div
            key={i}
            className={classNames(styles.item, {
              [styles.selected]: activePageId === item.id,
            })}
            onClick={() => setActivePageId(item.id)}
          >
            <div className={styles.icon}>
              <FontAwesomeIcon icon={item.icon} size="2x" />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      activePageId: state.uistate.activePageId,
    };
  },
  (dispatch) => ({
    setActivePageId: (pageId) => dispatch(UIStateActions.setActivePageId(pageId)),
  }),
)(DrawerComponent);
