import React, {Component} from 'react';
import styles from './ScreenLayoutComponent.module.scss';

class ScreenLayoutComponent extends Component {
  render() {
    const { title, body, leftNode, rightNode } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.titleBar}>
          <div className={styles.left}>{leftNode}</div>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.right}>{rightNode}</div>
        </div>
        <div className={styles.body}>
          {body}
        </div>
      </div>
    );
  }
}

export default ScreenLayoutComponent;
